/* Utilitiy classes */
/* body {
  background-color: #25211e;
} */
.ant-layout {
  background-color: #25211e !important;
  min-height: 100vh;
}
.spin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}

.d-flex {
  display: flex;
}
.flex-row{
  flex-direction: row;
}
.flex-grid .flex-grid-child {
  padding: 0 12px;
}

.align-center-v {
  display: flex;
  align-items: center;
}

.align-start-v {
  display: flex;
  align-items: start;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}

.flex-column{
  flex-direction:column;
}

.align-items-center {
  align-items: center;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.line-height-0 {
  line-height: 0;
}

.min-h-75 {
  min-height: 73%;
  height: auto !important;
}
@media only screen and  (max-width: 1280px) {
  .min-h-75 {
    min-height: 62%;
    height: auto !important;
  }
}
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.medium-text {
  font-weight: 500;
}

.status {
  display: inline !important;
  font-size: 13px;
  font-weight: 500;
  background-color: #868eae10;
  padding: 4.5px 11.85px;
  border-radius: 15px;
}

.status.Success {
  background-color: #01b81a10;
  color: #01b81a;
}

.status.warning {
  background-color: #fa8b0c10;
  color: #fa8b0c;
}

.status.error {
  background-color: #FF0F0F10;
  color: #FF0F0F;
}

.modal-video-close-btn {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/* spacing classes */

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-10 {
  margin-left: 10px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}
@media(max-width: 1280px) {
  .mb-50 {
    margin-bottom: 20px;
  }
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-0 {
  padding-left: 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.pr-0 {
  padding-right: 0 !important;
}

.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}

/* default card style */

.ant-card {
  /* box-shadow: 0 5px 20px rgba(173,181,217,.05); */
}

.ant-card-body {
  padding: 25px !important;
}

.ant-card-head {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ant-card-head-title span {
  display: inline-block;
  margin-left: 15px;
  font-size: 11px;
  font-weight: 500;
  color: #868eae;
}

.ant-card-head .ant-card-extra {
  display: flex;
  align-items: center;
}

.ant-card-head .ant-card-extra:empty {
  display: none;
}

.ant-card-head .ant-card-extra a {
  color: #868eae;
}

.ant-card-extra .ant-dropdown-trigger {
  line-height: 0;
  order: 1;
  margin-left: 20px;
}

.ninjadash-unresizable {
  resize: none;
}

/* ninjaDash Delete Modal */

.ninjaDash-delete-modal .ant-modal-header {
  text-align: center;
  padding: 22px 24px;
}

.ninjaDash-delete-modal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  color: #ff0f0f;
}

.ninjaDash-delete-modal .ant-modal-body {
  padding: 20px 24px;
}

.ninjaDash-delete-modal .ninjadash-delete-confirm {
  text-align: center;
}

.ninjaDash-delete-modal .ninjadash-delete-confirm__action a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 32px;
  border-radius: 3px;
  color: #fff;
  background-color: #01b81a;
}

/* NinjaDash Export Modal */
.ninjadash_export-wrap .ninjadash-button-grp {
  margin: -5px;
}

.ninjadash_export-wrap .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
}

.ninjadash_export-wrap .ant-select-single:not(.ant-select-customize-input) .ant-select-selection-item {
  line-height: 50px !important;
}

.ninjadash_export-wrap .ninjadash-button-grp button {
  min-height: 44px;
}

.ninjadash_export-wrap .ninjadash-button-grp .ant-btn {
  margin: 5px;
}

/* ant radio group */

.ant-radio-button-wrapper-checked {
  color: #fff;
}

/* chart growth upward */

.growth-downward p,
.growth-upward p {
  font-size: 14px;
  color: #868eae;
  margin: 0;
}

.growth-downward h1 sub svg,
.growth-upward h1 sub svg {
  position: relative;
  top: 2px;
  font-size: 14px;
  font-weight: 600;
  left: 5px;
  bottom: 0;
}

/*
.growth-downward h1, .growth-upward h1 {
  font-size: 22px;
  margin: 6px 0 0;
} */

.growth-downward h1 sub {
  color: #FF0F0F;
}

.growth-upward h1 sub {
  color: #01b81a;
}

/* Chart */

.chart-label {
  display: flex;
}

.chart-label .chart-label__single {
  align-items: center;
}

.chart-label .chart-label__single:not(:last-child) {
  margin-right: 40px;
}

.chart-label .chart-label__single p {
  margin: 0;
  color: #868eae;
}

/* revenue doughnut */

.revenue-doughnut {
  display: flex;
  justify-content: center;
}

.revenue-doughnut>div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.revenue-doughnut .rd-labels {
  width: 100%;
  margin-top: 30px;
}

.revenue-chat-percentage span {
  display: block;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 15px;
}

/* progressbars */

.ant-progress {
  display: inline-flex !important;
  align-items: center;
}

.ant-progress>div {
  display: flex;
  flex-direction: column;
}

.ant-progress .ant-progress-outer {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.revenue-chat-percentage span {
  display: block;
  font-size: 13px;
  color: #868eae;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 20px;
}

.ant-progress-status-warning .ant-progress-bg {
  background: #fa8b0c;
}

/* progress bars */

.progress-primary .ant-progress-bg {
  background: #8231D3;
}

.progress-secondary .ant-progress-bg {
  background: #ff69a5;
}

.progress-success .ant-progress-bg {
  background: #01b81a;
}

.progress-info .ant-progress-bg {
  background: #00aaff;
}

.progress-warning .ant-progress-bg {
  background: #fa8b0c;
}

.progress-danger .ant-progress-bg {
  background: #FF0F0F;
}

/* color classes */

.color-primary {
  color: #8231D3;
}

.color-secondary {
  color: #5840ff;
}

.color-info {
  color: #00AAFF;
}

.color-warning {
  color: #fa8b0c;
}

.color-success {
  color: #01b81a;
}

.color-danger {
  color: #FF0F0F;
}

.color-dark {
  color: #0A0A0A;
}

.color-error {
  color: #f5222d;
}

.color-gray {
  color: #404040;
}

/* Button Styles */

.button-example .ant-btn {
  margin: 4px;
}

.button-example .ant-btn-group .ant-btn {
  margin: 0;
}

.ant-btn.ant-btn-white {
  color: #404040;
  border-color: #e3e6ef;
}

.ant-btn.ant-btn-white svg {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn.ant-btn-white:hover {
  color: #8231D3;
  background-color: #fff;
  border-color: #8231D3;
}

.ant-btn.ant-btn-white:hover svg {
  color: #8231D3;
}

.ant-btn-white:focus {
  background-color: transparent !important;
}

.ant-btn.ant-btn-dashed {
  border-width: 1px !important;
  color: #090e30;
}

.ant-btn.ant-btn-dashed:hover {
  color: #8231D3;
  border: 1px dashed #8231D3;
}

.ant-btn-primary[disabled] {
  color: #fff;
  background: #8231D360;
}

.ant-btn-light[disabled] {
  background: #fff;
}

.ant-btn-round.ant-btn-sm {
  height: 38px;
}

.ant-btn-white[disabled] {
  background-color: transparent;
  opacity: 0.6;
}

.ant-btn-white[disabled]:hover {
  color: #404040 !important;
}

.ant-btn-primary[disabled]:hover {
  color: #fff !important;
  background: #8231D360 !important;
}

.btn-icon {
  padding: 0 13px;
}

.btn-inc,
.btn-dec {
  height: 38px;
  width: 38px;
  font-size: 20px;
  padding: 0 12px !important;
  border-radius: 10px !important;
  border: 0 none;
}

.btn-inc:hover,
.btn-dec:hover {
  background: #8231D310 !important;
  border: 0 none !important;
}

.btn-inc:hover i,
.btn-inc:hover svg,
.btn-dec:hover i,
.btn-dec:hover svg {
  color: #8231D3;
}

/* input styles */

.ant-form-item-label>label {
  font-weight: 700;
}

.ant-picker-input>input::placeholder {
  color: #adb4d2 !important;
}

.ant-input-affix-wrapper,
.ant-input {
  border-radius: 6px;
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-right: 8px;
}

.ant-input-affix-wrapper-lg .ant-input-lg {
  font-size: 15px;
}

/* Calendar Styles */

.ant-picker-calendar-header .ant-select-selector {
  height: 32px !important;
}

.ant-picker-calendar-header .ant-select-selection-search-input {
  height: 30px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
}

/* pagination */

.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
}

.ant-pagination-item-active {
  background-color: rgba(123, 232, 255, 1) !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination .ant-pagination-options .ant-select-selection-item {
  font-size: 13px;
  line-height: 30px !important;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  height: 30px;
  line-height: 30px;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-options .ant-select-selector {
  border: 1px solid #f1f2f6 !important;
  background-color: #fff;
  border-radius: 17px !important;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  color: #404040 !important;
  line-height: 2.6;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link {
  display: block;
}

.ant-pagination-prev,
.ant-pagination-next {
  line-height: 28px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 0 none !important;
  border-radius: 17px !important;
}

.ant-pagination .ant-pagination-item a {
  color: #404040;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0px;
  padding-right: 20px;
}
.table_custom_pagination.ant-table-pagination.ant-pagination{
  margin-top: 4px !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  font-family: "Work Sans", sans-serif !important;
}

.ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
  color: #404040;
}

.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-pagination-item,
.ant-pagination-options,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-next {
  margin: 4px !important;
}

/* .ant-table-pagination {
  margin-top: 30px !important;
} */

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table table tr th.ant-table-selection-column,
.ant-table table tr td.ant-table-selection-column {
  padding: 0 16px;
}
/* 
.waivo_pay_report_table .ant-table table thead th:nth-child(6) , .waivo_pay_report_table .ant-table table .ant-table-tbody tr td:nth-child(6){
  min-width: 215px;
} */

.waivo_pay_report_table .ant-table table thead th:nth-child(6) , .waivo_pay_report_table .ant-table table .ant-table-tbody tr td:nth-child(6){
  min-width: 250px;
}

/* Wizard Modal */
.submission-successModal {
  text-align: center;
}

.submission-successModal .icon-success {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #01b81a;
}

.submission-successModal p {
  margin-bottom: 0;
}

.submission-successModal .icon-success svg {
  color: #01b81a;
}

.submission-successModal .submission-action {
  margin: 30px -5px -5px -5px;
}

.submission-successModal .submission-action button {
  margin: 5px;
  height: 38px;
}

/* Select */

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #e3e6ef;
  border-radius: 6px !important;
}

/* Tree Select */

.ant-tree-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px !important;
  line-height: 40px;
}

.ant-tree-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-tree-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}

/* radio style */

.ant-radio-checked .ant-radio-inner {
  border-width: 4px !important;
}

.ant-radio-inner::after {
  content: none !important;
}

/* Statistics */

.ant-statistic .ant-statistic-title {
  color: #9299b8;
}

.ant-statistic .ant-statistic-content span {
  font-size: 20px;
}

/* Steps */

.steps-action.justify-content-center {
  justify-content: center !important;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role="button"]:hover .ant-steps-icon svg {
  color: #8231D3;
}

/* rate */

.ant-rate-star.ant-rate-star-zero span svg {
  color: #c6d0dc;
}

.ant-rate-star:not(:last-child) {
  margin-right: 2px !important;
}

/* result */

.ant-result-icon {
  margin-bottom: 20px !important;
}

.ant-result-title {
  font-weight: 500;
  margin-bottom: 10px;
}

.ant-result-extra {
  height: 34px;
  padding: 4px 10.72px;
}

.ant-result-content .ant-typography strong {
  font-weight: 500;
}

.ant-result-content .ant-typography:last-child {
  margin-bottom: 0;
}

/* form select */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 35px !important;
}

.ant-select-multiple .ant-select-selector {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.ninjadash-fullwidth-select {
  width: 100%;
}

/* calendar style */

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}

.notes-month {
  text-align: center;
  font-size: 28px;
}

.notes-month section {
  font-size: 28px;
}

/* Breadcrumb demo */

.demo {
  margin: 16px;
}

.demo-nav {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8;
}

.demo-nav a {
  line-height: 30px;
  padding: 0 8px;
}

.app-list {
  margin-top: 16px;
}

/* Modal */

.ant-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-modal-body p:last-child {
  margin-bottom: 0;
}

.project-modal {
  padding: 5px !important;
}

.project-modal .projects-members {
  margin-top: -12px;
}

.project-modal .projects-members img {
  margin: 6px;
}

.project-modal .ant-form-item-control-input {
  /* margin-top: 10px; */
  margin-top: 0px;
  min-height: auto;
}

.project-modal .ant-select-selector {
  border-color: #e3e6ef;
}

.project-modal .ant-picker {
  width: 100%;
}

.project-modal-footer {
  padding: 10px !important;
  text-align: left !important;
}

.ninjadash-addTask-modal {
  max-width: 390px;
}

.ninjadash-addTask-modal .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 0 !important;
}

.ninjadash-addTask-modal .ant-modal-header .ant-modal-title {
  font-size: 16px;
  font-weight: 500;
  color: #0A0A0A;
}

.ninjadash-addTask-modal .ninjadash-addTask-modal-inner .ant-form-item {
  margin-bottom: 15px;
}

.ninjadash-addTask-modal .ant-modal-header .ant-modal-close-x svg {
  color: #9299b8;
}

.ninjadash-addTask-modal .ant-form-item-control-input {
  min-height: 36px;
}

.ninjadash-addTask-modal input::placeholder,
.ninjadash-addTask-modal textarea::placeholder {
  color: #9299b8;
}

.ninjadash-addTask-modal input,
.ninjadash-addTask-modal textarea {
  padding: 6px 20px;
  border-radius: 5px;
}

.ninjadash-addTask-modal textarea {
  resize: none;
  min-height: 125px;
}

.ninjadash-addTask-modal .ninjadash-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin: -6px;
}

.ninjadash-addTask-modal .ninjadash-modal-actions button {
  font-size: 14px;
  margin: 6px;
  height: 38px;
  border-radius: 5px;
}

/* slider */

.ant-slider-handle {
  margin-top: -6px !important;
}

.ant-slider .ant-slider-rail,
.ant-slider-track,
.ant-slider .ant-slider-step {
  height: 3px;
}

/* tags */

.ant-tag {
  font-weight: 600;
  padding: 0 9.5px;
}

/* tabs */

.ant-tabs-tab span {
  display: flex;
  align-items: center;
}

/* popover */

.ant-popover {
  position: fixed !important;
  z-index: 99998 !important;
}

.ant-popover .ant-btn-sm {
  font-size: 13px;
}

.ant-popover .ant-popover-inner {
  padding: 15px;
  box-shadow: 0 15px 25px rgba(146, 153, 184, .20);
  border-radius: 6px;
}

.ant-popover-inner .ant-popover-title {
  padding: 5px 10px 10px;
}

.ant-popover-inner .ant-popover-inner-content a {
  color: #404040;
}

/* Drawer */

.ant-drawer {
  z-index: 99999;
}

/* Select Dropdwon */

.ant-select-dropdown {
  padding: 18px 0 !important;
  box-shadow: 0 5px 20px #9299b820 !important;
  border-radius: 0 0 6px 6px !important;
}

.ant-select-item {
  min-height: 20px !important;
  padding: 4px 12px !important;
}

.ant-select-item-group {
  color: #9299b8;
}

.ant-select-item.ant-select-item-option-grouped {
  padding-left: 25px !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
  background: #8231D305;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  padding-left: 10px;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  color: #8231D3;
  background: #8231D306;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  color: #8231D3;
  font-weight: 500;
}

.ant-select-dropdown .ant-select-item .ant-select-item-option-content {
  transition: 0.3s;
  color: #fff;
}

/* mail props dropdown */

.mail-props {
  padding: 10px 25px;
  border: 0 none;
  background: #fff;
  margin-bottom: 0;
  /* box-shadow: 0 5px 40px rgba(146, 153, 184, 0.25); */
}

.mail-props li {
  display: flex;
  margin-bottom: 12px;
}

.mail-props li span:first-child {
  margin-right: 50px;
  min-width: 40px;
}

.mail-props li span:last-child {
  color: #404040;
}

.mail-props li:last-child {
  margin-bottom: 0;
}

.mail-props li span {
  color: #9299b8;
}

/* Basic Dropdwon */

.ant-dropdown {
  box-shadow: 0 5px 30px #9299b820 !important;
}

.ant-dropdown.wide-dropdwon {
  min-width: 140px !important;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more {
  min-width: 220px !important;
  box-shadow: 0 17px 20px #9299b820;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more a {
  padding: 10px 24px;
}

.ant-dropdown-menu {
  min-width: 200px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0;
}

.ant-dropdown-menu li {
  color: #404040;
  padding: 5px 25px;
}

.ant-dropdown-menu li:hover {
  background-color: #8231D305;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__title {
  background: #f4f5f7;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  min-height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ninjadash-top-dropdown {
  width: 340px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav {
  height: 260px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav.notification-list {
  padding: 0 10px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li {
  width: 100%;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a {
  padding: 13px 10px;
  position: relative;
  width: 100%;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li:last-child {
  margin: 0;
}

.ninjadash-top-dropdown a.btn-seeAll {
  position: relative;
  width: calc(100% + 30px);
  left: -15px;
  right: -15px;
  height: calc(100% + 15px);
  bottom: -15px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #8231D3;
  padding: 15px 0;
  border-radius: 0 0 6px 6px;
  justify-content: center;
  background: #fff;
  transition: 0.3s;
}

.ninjadash-top-dropdown a.btn-seeAll:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 -15px 20px #9299b808;
  z-index: 1;
  content: "";
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a.btn-seeAll:hover:after {
  box-shadow: 0 0;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover {
  background: #fff;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover:after {
  opacity: 1;
  visibility: visible;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover figcaption h1 {
  color: #8231D3;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:after {
  position: absolute;
  left: -15px;
  right: -15px;
  top: 0;
  width: calc(100% + 30px);
  height: 100%;
  box-shadow: 0 15px 50px #9299b820;
  z-index: 1;
  content: "";
  opacity: 0;
  visibility: hidden;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-icon {
  width: 39.2px;
  height: 32px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-icon.bg-primary {
  background: #8231D315;
  color: #8231D3;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-icon.bg-secondary {
  background: #5840FF15;
  color: #5840FF;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-icon svg {
  width: 18px;
  height: 18px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ninjadash-top-dropdown .notification-text h1 {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
  margin-bottom: 4px;
}

.ninjadash-top-dropdown .notification-text h1 span {
  color: #8231D3;
  font-weight: 500;
  padding-left: 0;
}

.ninjadash-top-dropdown .notification-text p {
  font-size: 12px;
  color: #adb4d2;
  margin-bottom: 0;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content img {
  max-width: 40px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption {
  margin: -4px 15px 0;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption .ant-badge-count {
  font-size: 8px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 8px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption p.ant-scroll-number-only-unit.current {
  height: 15px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption h1 span {
  font-size: 12px;
  font-weight: 400;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.add-file-dropdown a {
  font-size: 14px;
  font-weight: 400;
}

.add-file-dropdown a svg {
  width: 14px;
}

.folder-dropdown {
  min-width: 200px !important;
}

/* Tooltip Styles */

.ant-tooltip .ant-tooltip-inner {
  min-height: 40px;
  padding: 6px 20px;
  font-size: 14px;
  color: #404040;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: 1px solid #f1f2f6;
  box-shadow: 0 8px 15px #9299b815;
}

.ant-tooltip .ant-tooltip-arrow:after {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  width: 13px;
  height: 13px;
  background: #fff;
  content: "";
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  width: 14px;
  height: 14px;
  top: 3px;
  background: #fff;
  z-index: 98;
  --antd-arrow-background-color: #fff;
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content:before {
  position: static;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow .ant-tooltip-arrow-content {
  border: 1px solid #e3e6ef;
  box-shadow: 0 0;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  top: -4.071068px;
}

.ant-slider-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow {
  transform: translateY(80%) translateX(-50%);
}

/* Badge Styles */

.ant-badge.badge-success .ant-badge-count {
  background: #01b81a;
}

.badge {
  font-size: 11px;
  font-weight: 500;
  padding: 0 6.5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.badge .ant-badge-count {
  box-shadow: 0 0;
}

.badge.badge-primary {
  background: #8231D315;
  color: #8231D3;
}

/* Cascade Styles */

.custom-cascade-render .ant-cascader-picker {
  width: 100%;
}

.ant-cascader-picker input::placeholder {
  color: #adb4d2;
}

/* Comment Styles */

.ant-comment-actions li {
  margin-bottom: 0 !important;
}

/* Radio Vertical Button */

.ant-radio-vertical .ant-radio-wrapper {
  display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
  margin-bottom: 14px;
}

/* Select Tree */

.ant-select-tree-list .ant-select-tree-node-content-wrapper:hover {
  background-color: #bae7ff !important;
}

.ant-select-tree-list .ant-select-arrow svg {
  font-size: 10px;
}

.ant-tree-select.ant-select-multiple .ant-select-selection-item {
  border: 0 none;
  border-radius: 3px;
  background: #f4f5f7;
  color: #404040;
  font-weight: 500;
}

/* Ant Switch */

.ant-switch:after {
  width: 14px !important;
  height: 14px !important;
}

.ant-switch-small:after {
  width: 10px !important;
  height: 10px !important;
}

/* Time Picker */

.ant-picker {
  min-width: 250px;
}

/* Input Affix */

.ant-input-affix-wrapper>input.ant-input {
  padding-left: 5px;
}

.ant-input-affix-wrapper .ant-input-prefix svg {
  color: #e3e6ef;
}

.ant-input-affix-wrapper {
  padding: 8px 20px;
}

/* Space Item */

.ant-space-item .ant-btn span {
  font-size: 14px;
}

/* Pop confirm */

.pop-confirm .ant-btn {
  max-width: 90px;
  padding: 0px 35.5px;
  height: 44px;
}

.pop-confirm-top {
  margin-bottom: 10px;
}

.pop-confirm-bottom {
  margin-top: 10px;
}

.pop-confirm-top .ant-btn:not(:last-child),
.pop-confirm-bottom .ant-btn:not(:last-child) {
  margin-right: 10px;
}

.pop-confirm-left .ant-btn:not(:last-child),
.pop-confirm-right .ant-btn:not(:last-child) {
  margin-bottom: 10px;
}

/* Ant Upload */

.ant-upload .ant-btn {
  font-size: 14px;
  border-radius: 5px;
}

.ant-upload-select{
  cursor: pointer;
}

.ant-upload.ant-upload-select-picture-card {
  border-color: #e3e6ef !important;
  border-radius: 5px !important;
  background-color: #f8f9fb !important;
}

.ant-upload.ant-upload-select-picture-card .anticon {
  margin-bottom: 8px;
}

.ant-upload .anticon svg {
  color: #9299b8;
}

.ant-upload-list {
  margin-top: 10px !important;
}

.ninjadash-upload-basic .ant-upload.ant-upload-select {
  width: 100%;
  border: 1px solid #e3e6ef;
  border-radius: 4px;
}

.ninjadash-upload-basic .ant-upload.ant-upload-select .ant-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ninjadash-upload-basic .ninjadash-upload-text {
  font-size: 14px;
  color: #9299b8;
  padding-left: 15px;
}

html[dir="rtl"] .ninjadash-upload-basic .ninjadash-upload-text {
  padding-left: 0px;
  padding-right: 15px;
}

.ninjadash-upload-basic .ninjadash-upload-browse {
  font-size: 14px;
  color: #9299b8;
  display: inline-block;
  padding: 14px 23px;
  border-left: 1px solid #e3e6ef;
}

html[dir="rtl"] .ninjadash-upload-basic .ninjadash-upload-browse {
  border-left: 0 none;
  border-right: 1px solid #e3e6ef;
}

.ant-modal-wrap-rtl .ninjadash-event-details-modal .ant-modal-close {
  left: 10px;
}

.ant-card.ninjadash-upload-form .ant-card-body {
  padding-bottom: 15px !important;
}
.ant-modal-wrap .modal-xlg{
  width: 800px !important;
 }
.ant-modal-wrap .modal-lg{
 width: 700px !important;
}

.ant-modal-wrap .modal-sm{
  width: 350px !important;
 }

 /* Changed by Gautam for removing uncontrolled file upload */
 .ant-upload-list, .ant-upload-list-text{
    display: none;
 }
 
/* Ant Picker */

.ant-picker {
  border-color: #e3e6ef;
}

/* Ant Dropdown */

.ant-dropdown {
  box-shadow: 0 5px 20px #9299b820;
  background: #fff;
  border-radius: 6px;
  border: 0 none;
}

.ant-dropdown a {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  font-weight: 400;
  color: #404040;
}

.ant-dropdown a i,
.ant-dropdown a svg,
.ant-dropdown a img {
  margin-right: 8px;
  width: 14px;
  height: 14px;
}

.ant-dropdown div {
  box-shadow: 0 0;
  border-radius: 5px;
}

/* Picker Under Input */

.ant-form-item-control-input .ant-picker {
  padding: 0 12px 0 12px;
  min-height: 38px;
  border-color: #e3e6ef;
}

/* Leaflet COntainer */

.leaflet-container {
  z-index: 0;
}

/* Table Bordered*/
.table-bordered .ant-table-thead>tr>th {
  background: #fff;
  border-top: 1px solid #f1f2f6;
}

.table-bordered .ant-table-tbody>tr>td {
  border-color: #f1f2f6 !important;
}

.table-bordered .ant-table-thead tr th,
.table-bordered .ant-table-tbody tr td {
  padding: 16px 25px;
}

.table-bordered .ant-table-thead tr th:last-child,
.table-bordered .ant-table-tbody tr td:last-child {
  text-align: right;
}

/* Table Responsive */

.table-responsive .ant-table-content {
  display: block;
  width: 100%;
  overflow: auto;
  min-height: calc(100vh - 220px);
  max-height: calc(100vh - 220px);
}

.table-responsive .ant-table-content .ant-table-cell {
  white-space: nowrap;
}
.table-responsive .ant-table-content .ant-table-cell .coverage{
  width: 140px;
}
.table-responsive .ant-table-content .ant-table-cell figcaption h1 span,
.table-responsive .ant-table-content .ant-table-cell figcaption h1 a span {
  font-weight: 700;
  color: rgba(37, 33, 30, 1);
  text-decoration: none;
}
.table-responsive .ant-table-content .ant-table-cell figcaption h1 span a  {
  font-weight: 500;
  color: rgba(37, 33, 30, 1);
  text-decoration: underline;
}

.table-responsive .ant-table-content .ant-table-cell  a.urlLink, a.common_urlLink {
  font-weight: 500;
  color: rgba(37, 33, 30, 1);
  text-decoration: underline;
  line-height: normal;
}
.table-responsive .ant-table-content .ant-table-cell a.unurlLink {
  font-weight: 700;
  color: rgba(37, 33, 30, 1);
  text-decoration: none;
}

.table-responsive .ant-table-content .ant-table-cell h1 span.boldLink {
  font-weight: 700;
  color: rgba(37, 33, 30, 1);
  /* text-decoration: underline !important; */
}
.table-responsive .ant-table-content .ant-table-cell div a.boldurlLink {
  font-weight: 700;
  color: rgba(37, 33, 30, 1);
  text-decoration: underline !important;
}
.table-responsive .ant-table-content .ant-table-cell div a.boldurlLink.pending_agreement {
  text-decoration: unset !important;
}
.table-responsive .ant-table-content .ant-table-cell div .boldurlLink.pending_agreement{
  font-weight: 700;
}

.table-responsive .ant-table-content .ant-table-cell figcaption {
  line-height: 0.5;
}
.table-responsive .ant-table-content .ant-table-cell .host_name_figcaption{
  line-height: 1.2;
}

.table-responsive .ant-table-content .ant-table-cell .coverage a, .coverage a {
  font-weight: 500;
  color: rgba(37, 33, 30, 1);
  text-decoration: none;
  background: rgba(123, 232, 255, 1);
  border: 1.5px solid rgba(37, 33, 30, 1);
  border-radius: 50px;
  padding: 6px 15px;
  display: inline-block;
  font-size: 14px;
}
.coverage.details a{
  margin-top: 5px;
}
.table-responsive .ant-table-content .ant-table-cell .idCard a figure {
  margin:0;
}
.table-responsive .ant-table-content .ant-table-cell .idCard a {
  display:block;
  width: 39px;
  height: 39px;
}
.table-responsive .ant-table-content .ant-table-cell .idCard a figure img {
  border-radius: 4px;
  width: 39px;
  height: 39px;
  margin: 0;
  min-height: 39px;
  max-height: 39px;
  object-fit: fill;
}
.table-responsive .ant-table-content .ant-table-column-sorter{
  margin-left: 0;
}
.table-responsive .ant-table-content .ant-table-column-title{
  order: 1;
  padding-left: 10px;
}

/* Rich TextEditor  */

.RichTextEditor__root___2QXK- {
  border: 0 none !important;
}

.RichTextEditor__root___2QXK- .EditorToolbar__root___3_Aqz {
  margin: 0;
  border-color: #f1f2f6;
}

.RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button {
  padding: 0;
  border: 0 none;
  background: #fff;
  margin-right: 8px;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx .Dropdown__value___34Py9 {
  border: 0 none;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx select {
  border-right-width: 0px;
  direction: ltr;
}

.RichTextEditor__editor___1QqIU .DraftEditor-editorContainer {
  border: 0 none;
}

/* ChatBox Dropdwon */

.atbd-chatbox__messageControl {
  min-width: 210px;
}

.atbd-chatbox__messageControl ul {
  margin: 0;
  padding: 10px 0;
}

.atbd-chatbox__messageControl ul li a {
  padding: 4px 24px;
}

.atbd-chatbox__emoji {
  margin: -4.48px 0;
  padding: 0 10px;
}

.atbd-chatbox__emoji ul {
  display: flex;
  align-items: center;
}

.atbd-chatbox__emoji ul li {
  display: inline-block;
}

.atbd-chatbox__emoji ul li a {
  display: block;
  font-size: 20px;
  padding: 4px 7px;
  background: #fff;
}

.atbd-chatbox__emoji ul li a:hover {
  background-color: transparent;
}

.atbd-chatbox__emoji ul li a svg {
  margin: 0;
}

.rdrMonths {
  flex-wrap: wrap;
}

.ant-space {
  flex-wrap: wrap;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-item {
  display: inline-flex;
}

.ant-menu-submenu-popup {
  z-index: 105;
}

.ant-menu-submenu-popup .ant-menu-sub {
  padding: 8px 0;
}

.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-item {
  display: block;
}

.ant-menu-submenu-popup .ant-menu-item {
  margin-bottom: 0 !important;
}

.ant-menu-sub.ant-menu-vertical {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.ant-menu-sub.ant-menu-vertical .ant-menu-item a {
  color: #404040;
}

.ant-menu {
  background:none;
}

/* Ninjadash Header More */
.ninjadash-header-more .ninjadash-nav-actions__searchbar {
  display: none;
}

/* page heading */

.ant-page-header.header-boxed {
  padding: 26px 180px 26px 180px !important;
}

.ant-page-header-heading {
  align-items: center;
}

.ant-page-header-heading-title {
  text-transform: capitalize;
}

.ant-page-header-heading-sub-title {
  position: relative;
  top: 2px;
}

/* Swiper Sldier */

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

/* Antd drag & drop */

.row-dragging {
  /* background: #fafafa; */
  /* border: 1px solid #ccc; */
  box-shadow: 0 15px 50px #9299b820;
  display: flex;
}

.row-dragging tr {
  box-shadow: 0 15px 50px #9299b820;
}

.row-dragging td {
  padding: 16px;
  color: #000;
  position: relative;
  z-index: 9999;
  opacity: 0.5;
  vertical-align: middle;
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #01b81a;
  border-color: #01b81a;
}

.row-dragging td .todos-action,
.row-dragging td .table-actions {
  display: none;
}

.row-dragging td .user-info figcaption {
  display: none;
}

.ant-table .user-info img.user-info-image{
  width: 75px !important;
}

.ant-table .user-info img.map_image_listing{
  width: 75px !important;
  height: 75px !important;
}

.row-dragging td .feather-move,
.row-dragging td .drag_email,
.row-dragging td .drag_company,
.row-dragging td .drag_designation,
.row-dragging td .drag_join-date,
.row-dragging td .active {
  display: inline-block;
  margin-top: 10px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* Ninjadash Event Details Modal */
.ninjadash-event-details-modal .ant-modal-content {
  border-radius: 8px;
}

.ninjadash-event-details-modal .ant-modal-close {
  top: 12px;
  right: 10px;
}

.ninjadash-event-details-modal .ant-modal-close-x svg {
  width: 14px;
  height: 14px;
  color: #ffffff75;
}

.ninjadash-event-details-modal .ant-modal-close-x {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  line-height: 1;
  background-color: transparent;
}

.ninjadash-event-details-modal .ant-modal-close-x:hover {
  background-color: #ffffff15;
}

.ninjadash-event-details-modal .ant-modal-close-x:hover svg {
  color: #ffffff;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-primary .ant-modal-header {
  background-color: #8231D3;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-secondary .ant-modal-header {
  background-color: #5840ff;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-success .ant-modal-header {
  background-color: #01b81a;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-info .ant-modal-header {
  background-color: #00aaff;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-warning .ant-modal-header {
  background-color: #fa8b0c;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-danger .ant-modal-header {
  background-color: #ff0f0f;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-dark .ant-modal-header {
  background-color: #090e30;
}

.ninjadash-event-details-modal .ant-modal-header {
  padding: 19px 24px;
  border-radius: 8px 8px 0 0;
}

.ninjadash-event-details-modal .ant-modal-header .ant-modal-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

/* File Export Import Modal */
.ninjadash-export-wrap .ant-modal {
  width: 390px !important;
}

.ninjadash-export-wrap .ant-modal .ant-modal-header {
  border-bottom: 0 none;
  padding: 18px 30px 0 30px;
}

.ninjadash-export-wrap .ant-modal .ant-modal-body {
  padding: 25px 30px 30px 30px;
}

.ninjadash-export-wrap .ant-modal .ninjadash-button-grp {
  text-align: right;
}

.ninjadash-export-wrap .ant-modal .ninjadash-button-grp {
  margin: 20px -8px -8px -8px;
}

.ninjadash-export-wrap .ant-modal .ninjadash-button-grp button {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  height: 40px;
  padding: 0 16px;
  margin: 8px;
}

.ninjadash-export-wrap .ant-modal .ninjadash-button-grp button.ant-btn-white {
  color: #9299b8 !important;
}

.ninjadash-export-wrap .ant-form-item-control-input {
  min-height: 36px;
}

.ninjadash-export-wrap .ant-form-item-control-input input {
  font-size: 14px;
  font-weight: 400;
  padding: 6px 20px;
  border-radius: 5px;
  color: #9299b8;
}

.ninjadash-export-wrap .ant-select-single {
  width: 100% !important;
}

.ninjadash-export-wrap .ant-form .ant-form-item {
  margin-bottom: 15px;
}

.ninjadash-export-wrap .ant-select-single .ant-select-selector {
  padding: 0 20px;
  border-color: #e3e6ef !important;
}

.ninjadash-create-file .ninjadash-button-grp {
  text-align: right;
}

.ninjadash-create-file .ant-modal {
  width: 390px !important;
}

.ninjadash-create-file .ant-modal .ant-form-item {
  margin-bottom: 20px;
}

.ninjadash-create-file .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 6px;
}

.ninjadash-create-file .ninjadash-button-grp button {
  height: 40px;
  border-radius: 5px;
  margin: 5px;
}

.ninjadash-create-file .ant-form-item-control-input {
  border-radius: 5px;
}

.ninjadash-create-file .ant-form-item-control-input input {
  border: 1px solid #e3e6ef;
}

/* Task Modal */
.ninjadash-task-details .ant-modal-content .ant-modal-close {
  top: 10px;
}

.ninjadash-task-details .ant-modal-header {
  border-bottom: 0 none;
  padding: 30px 30px 0;
}

.ninjadash-task-details .ant-modal {
  width: 600px !important;
}

.ninjadash-task-details .ant-modal-header .ant-modal-title h4 {
  font-size: 20px;
  font-weight: 500;
  color: #0A0A0A;
  margin-bottom: 4px;
}

.ninjadash-task-details .ant-modal-header .ant-modal-title .sub-text {
  font-size: 14px;
  font-weight: 400;
  color: #868eae;
}

.ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details__label {
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details-modal__description textarea {
  padding: 10px 20px;
  min-height: 88px;
  width: 100%;
  border: 0 none;
  border-radius: 4px;
  background-color: #f4f5f7;
  resize: none;
}

.ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details-modal__description textarea:focus {
  outline: none;
}

.ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details-modal__description textarea::placeholder {
  color: #404040;
  font-size: 15px;
}

.ninjadash-task-details .ant-modal-body {
  padding: 14px 30px 30px 30px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block {
  margin-top: 26px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-wrap {
  position: relative;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form {
  position: absolute;
  width: 240px;
  padding: 18px;
  left: 0;
  top: 50px;
  box-shadow: 0 15px 30px #9299bb30;
  background-color: #fff;
  border: 1px solid #e3e6ef;
  border-radius: 6px;
  z-index: 222;
}

html[dir="rtl"] .ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form {
  left: auto;
  right: 0;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .add-checklist {
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 38px;
  border: 1px solid #e3e6ef;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .addChecklist-form-action {
  margin-top: 15px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .addChecklist-form-action a {
  position: relative;
  top: 3px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .addChecklist-form-action a svg {
  color: #9299bb;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .addChecklist-form-action .btn-add {
  margin-right: 15px;
}

html[dir="rtl"] .ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .addChecklist-form-action .btn-add {
  margin-left: 15px;
  margin-right: 0;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block button {
  height: 38px;
  padding: 0px 18.37px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row {
  margin-top: 26px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item:not(:last-child) {
  margin-bottom: 30px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item__top button {
  padding: 0px 16.32px;
  color: #525768;
  background-color: #f4f5f7;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item__title {
  font-size: 16px;
  font-weight: 500;
  color: #090e30;
  margin-bottom: 0;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist__progress .ant-progress-inner .ant-progress-bg {
  height: 5px !important;
  background-color: #01b81a;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist__progress .ant-progress .ant-progress-text {
  font-size: 12px;
  font-weight: 500;
  order: -1;
  margin: 0 10px 0 0;
}

html[dir="rtl"] .ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist__progress .ant-progress .ant-progress-text {
  margin: 0 0 0 10px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-tasks {
  margin: 5px 0 5px 0;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-tasks .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-color: #c6d0dc;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-tasks .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #01b81a;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-tasks .ant-checkbox-wrapper .ant-checkbox-checked:after {
  border-color: #01b81a;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-item .ninjadash-checklist-tasks-wrap button {
  padding: 0px 35px;
  color: #525768;
  background-color: #f4f5f7;
}

.ninjadash-task-details .ant-modal-body {
  max-height: 800px;
  overflow-y: auto;
}

@media (max-width: 776px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px;
  }  
}

.page-header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -6px -4px;
}

.page-header-actions button {
  font-size: 12px;
  font-weight: 500;
  height: 34px;
  padding: 0 12.32px;
  box-shadow: 0 3px 5px #9299b805;
  margin: 6px 4px;
}

.page-header-actions button.ant-btn-white:focus {
  background-color: #fff !important;
}

.page-header-actions button+button {
  margin-left: 8px;
}

.page-header-actions button.ant-btn-white svg {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  color: #8231D3;
}

/* Layout Css */

.ant-menu-dark .ant-menu-item a {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark .ant-menu-item a:hover {
  color: #ffffff;
}

.ant-menu-dark .ant-menu-submenu span {
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected-tab {
  background-color: rgba(255, 255, 255, 0.05);
}

.ant-menu-inline-collapsed-tooltip a {
  color: #000 !important;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: transparent;
}

/* Chart Label */

.chart-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  color: #404040;
}

.chart-label .label-dot {
  margin-right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.chart-label .label-dot.dot-success {
  background: #01b81a;
}

.chart-label .label-dot.dot-info {
  background: #8231D3;
}

.chart-label .label-dot.dot-warning {
  background: #fa8b0c;
}

/* NOtification CSS */

.ant-notification {
  z-index: 99999 !important;
}

.rdrInputRange {
  padding-left: 10px !important;
}

/* Overlay Dark */
.overlay-dark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #10122130;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.overlay-dark.show {
  z-index: 998;
  opacity: 1;
  visibility: visible;
}

/* Responsive CSS */

@media only screen and (max-width: 1599px) {
  .ant-page-header.header-boxed {
    padding: 26px 130px 26px 130px !important;
  }
}

@media only screen and (max-width: 1500px) {
  .full-width-table .revenue-table {
    min-height: 100%;
  }

  .pop-confirm .ant-btn {
    padding: 0 20px;
    max-width: 60px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 300px !important;
  }

  .pop-confirm.pop-confirm-bottom,
  .pop-confirm.pop-confirm-top {
    margin-left: 80px !important;
  }
}

@media only screen and (max-width: 1399px) {
  .ant-page-header.header-boxed {
    padding: 26px 50px 26px 50px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .ant-page-header {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 991px) {
  .ant-page-header.header-boxed {
    padding: 26px 30px 26px 30px !important;
  }

  .rdrMonths .rdrMonth {
    width: 100%;
    margin-bottom: 30px;
  }

  .rdrDateRangePickerWrapper.PreviewArea {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 20px;
  }

  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .ant-page-header-heading-left {
    max-width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px;
  }

  .ant-page-header-heading {
    flex-flow: column;
    align-items: center;
    justify-content: center !important;
  }

  .ant-page-header-heading-left {
    max-width: 100%;
  }

  .ant-page-header-heading .ant-page-header-heading-title {
    white-space: normal;
    text-align: center;
  }

  .ant-page-header-heading-extra {
    white-space: normal !important;
    margin: 12px 0 4px !important;
  }

  .ant-page-header-heading-extra .page-header-actions {
    white-space: normal !important;
    text-align: center;
  }

  .ant-card-body {
    padding: 20px !important;
  }

  .ant-card-head {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ant-pagination .ant-pagination-item,
  .ant-pagination .ant-pagination-item.ant-pagination-prev,
  .ant-pagination .ant-pagination-item.ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev {
    margin-right: 5px;
  }

  .ant-pagination li.ant-pagination-item,
  .ant-pagination li.ant-pagination-prev,
  .ant-pagination li.ant-pagination-next,
  .ant-pagination li.ant-pagination-jump-prev,
  .ant-pagination li.ant-pagination-jump-next {
    font-size: 13px;
    height: 25px;
    min-width: 25px;
    line-height: 22px;
  }

  .ant-pagination li.ant-pagination-prev .anticon,
  .ant-pagination li.ant-pagination-next .anticon {
    vertical-align: 0.15em;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    line-height: 2;
    font-family: "Work Sans", sans-serif;
  }

  .ant-table-pagination {
    float: none !important;
    text-align: center;
  }

  .ant-table-pagination li.ant-pagination-total-text {
    display: block;
    margin-bottom: 8px;
  }

  .ant-table-pagination li {
    margin-right: 8px !important;
  }

  .ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px !important;
  }

  .ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 23px !important;
  }

  .rdrDefinedRangesWrapper {
    border-right: 0 none;
  }

  .project-modal .ant-picker {
    margin-bottom: 30px;
  }

  .project-modal {
    padding: 8px 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .mt-sm-10 {
    margin-top: 10px;
  }

  .ant-page-header-heading-left {
    margin-top: 0;
  }

  .btn-inc,
  .btn-dec {
    height: 30px;
    width: 30px;
  }

  .ant-page-header {
    padding: 26px 20px 16px;
  }

  .ant-layout-header {
    height: auto;
  }

  .ant-card-head {
    line-height: 1;
  }

  .ant-card-head-title {
    white-space: normal !important;
  }

  .pop-confirm .ant-btn {
    max-width: 60px;
    padding: 0 20px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 320px !important;
  }

  .pop-confirm.pop-confirm-bottom {
    margin-left: 95px !important;
  }

  /* Card Heading */
  .ant-card-head-wrapper {
    flex-flow: column;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0 0 16px !important;
    float: none;
    margin: 0;
    flex-flow: column;
  }

  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger+.card-nav {
    margin-bottom: 8px;
  }

  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger {
    margin: 0 !important;
  }

  .card-nav ul li.active:before {
    display: none;
  }

  .ant-card-head-title>div {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .ant-card-head-title>div span {
    margin: 8px 0 0;
  }

  .ant-picker-range-wrapper {
    min-width: auto !important;
  }

  .ant-picker-panel-container .ant-picker-panels,
  .ant-picker-datetime-panel {
    flex-direction: column;
  }
}

.bmzxig .ant-table tr th:first-child,
.bmzxig .ant-table tr td:first-child {
  padding-right: 15px;
}

/* Emprt */

.ant-empty .ant-empty-footer button {
  padding: 0px 15.58px !important;
}

/* Add Event Modal */
.addEvent-modal .ant-modal-header {
  padding: 20px 25px;
}

.addEvent-modal .ant-modal-header .ant-modal-close-x svg {
  color: #404040;
}

.addEvent-modal .ant-modal-header .ant-modal-title {
  font-size: 15px;
  font-weight: 500;
  color: #0A0A0A;
}

.addEvent-modal .ant-modal-body {
  padding: 23px 25px;
}

/* Event Dropdown */

.event-dropdown {
  min-width: auto !important;
  max-width: 450px;
  padding: 0 !important;
  margin: 6px 0 0 !important;
  box-shadow: 0 10px 40px #9299b820 !important;
}

.event-dropdown div {
  border-radius: 8px;
}

.event-dropdown .ant-card {
  width: 100% !important;
  margin-bottom: 0 !important;
}

.placement-confirm .ant-btn.ant-btn-white:hover {
  border-color: #8231D3;
}

@media only screen and (max-width: 479px) {
  .placement-confirm {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -10px 0 -5px;
  }

  .placement-confirm .pop-confirm {
    flex: 0 0 50%;
  }

  .pop-confirm .ant-btn {
    display: block;
    padding: 0 20px;
    max-width: 80px;
    min-width: 80px;
    margin: 10px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 0px !important;
  }

  .pop-confirm.pop-confirm-top {
    margin-left: 0px !important;
  }

  .pop-confirm.pop-confirm-bottom {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .ant-select {
    width: 100% !important;
  }

  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }

  .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
  }

  .ninjadash-top-dropdown {
    width: 280px;
    min-width: 180px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__title {
    min-height: 40px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__nav li:not(:last-child) {
    margin-bottom: 10px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a {
    padding: 10px 0px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__content img {
    margin-right: 15px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption {
    margin-left: 0;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption .ninjadash-top-dropdownText {
    min-width: 155px;
  }

  .ant-drawer-content-wrapper {
    width: 260px !important;
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 0;
  }
}

@media only screen and (max-width: 379px) {
  .ant-card-head-wrapper .ant-card-extra .ant-radio-button-wrapper {
    height: 32px !important;
    line-height: 30px !important;
  }

  .ant-notification-notice {
    width: 275px;
  }
}

@media (max-width: 575px) {
  .ant-card-body {
    padding: 15px !important;
  }
}

@media (max-width: 991px) {
  .mail-sideabr {
    box-shadow: 0 0 10px #00000020;
  }

  .ant-card-head-title span {
    margin-left: 10px;
  }
}

/* Invoice Print Style */
@media print {
  .invoice-area .ant-page-header {
    display: none;
  }

  .customizer-trigger {
    display: none;
  }
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
} */

/* custom label */

/* card spin loader */

.ant-card-body .sd-spin div,
.ant-card-body .spin div {
  position: relative;
}

.ant-card-body .sd-spin,
.ant-card-body .spin {
  height: 200px;
}

.ant-card-body {
  position: relative;
}

.ant-card-body .sd-spin .ant-spin,
.ant-card-body .spin .ant-spin {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 200px;
}

.starActive svg {
  color: red;
}

/* vectormap zoom in / zoom out */

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 27px;
  height: 27px;
  background: none;
  color: #404040;
  border: 1px solid #f1f2f6;
  padding: 0;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: auto;
  right: 0;
  top: auto;
  background-color: #fff;
}

.jvectormap-zoomin {
  border-radius: 6px 6px 0 0;
  bottom: 36px;
}

.jvectormap-zoomout {
  border-radius: 0 0 6px 6px;
  bottom: 10px;
}

.jvectormap-tip {
  padding: 7px 12px;
  border: 0 none;
  font-size: 12px;
  background: #0A0A0A;
}

.btn-rtl {
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  background: #4347d9;
  color: #fff;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -30px;
  z-index: 99999999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* masonry-grid start from here */

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 10px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.common-ul {
  padding: 5px 0;
  padding-left: 10px;
}

.display {
  display: none;
}

li.active>.display {
  display: block;
}

/* rtl section from here */
html[dir="rtl"] .ant-card-head-title span {
  margin-right: 15px;
}

html[dir="rtl"] span[aria-label="arrow-right"] svg,
html[dir="rtl"] span[aria-label="arrow-left"] svg {
  transform: rotateY(180deg);
}

html[dir="rtl"] .ant-menu.ant-menu-vertical {
  text-align: right !important;
}

html[dir="rtl"] .ant-menu.ant-menu-vertical .ant-menu-submenu-placement-rightTop {
  left: -170px !important;
}

html[dir="rtl"] .ant-menu.ant-menu-vertical .ant-menu-vertical-left {
  text-align: right;
}

html[dir="rtl"] .ant-modal-confirm .ant-modal-confirm-btns .ant-btn+.ant-btn {
  margin-left: 0;
  margin-right: 8px;
}

html[dir="rtl"] .ant-menu.ant-menu-vertical .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
  transform: rotateY(180deg);
}

html[dir="rtl"] .ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 15px !important;
}

html[dir="rtl"] .project-modal-footer {
  text-align: right !important;
}

html[dir="rtl"] .ant-card-extra .ant-dropdown-trigger {
  margin-right: 20px !important;
  margin-left: 0 !important;
}

html[dir="rtl"] .dark-mode .ninjadash-navbar-menu {
  margin-right: 20px;
}

/* Dark Mode Css */
.dark-mode .RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button span {
  display: flex;
}

.dark-mode .RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button {
  background: #a4a5aa;
}

.dark-mode .ninjadash-top-dropdown a.btn-seeAll,
.dark-mode .ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover,
.dark-mode .ant-picker-panel-container,
.dark-mode .editor-compose .ant-input,
.dark-mode .ant-drawer-content,
.dark-mode .ant-dropdown,
.dark-mode .mail-props,
.dark-mode .ant-popover-inner,
.dark-mode .ninjadash-searchbar .ant-input,
.dark-mode .ant-input-group-addon,
.dark-mode .ant-popover-arrow-content,
.dark-mode .ant-drawer-header,
.dark-mode .ant-popover-arrow-content:before {
  background: #1b1e2b;
}

.dark-mode .ant-btn.ant-btn-white,
.dark-mode .ant-notification-notice,
.dark-mode .ant-pagination-prev .ant-pagination-item-link,
.dark-mode .ant-pagination-next .ant-pagination-item-link,
.dark-mode .ant-pagination .ant-pagination-prev,
.dark-mode .ant-pagination .ant-pagination-next,
.dark-mode .ant-pagination .ant-pagination-jump-prev,
.dark-mode .ant-pagination .ant-pagination-jump-next,
.dark-mode .ant-pagination .ant-pagination-item,
.dark-mode .ant-pagination .ant-pagination-options .ant-select-selector,
.dark-mode .ant-modal .ant-modal-header,
.dark-mode .ant-modal .ant-modal-content,
.dark-mode .ant-modal .ant-modal-content .ant-card,
.dark-mode .ant-input,
.dark-mode .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.dark-mode .ant-form-item-control-input .ant-picker,
.dark-mode .ant-pagination-options-quick-jumper input {
  background-color: #282b37;
}

.dark-mode .ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details-modal__description textarea,
.dark-mode .ant-picker-range-arrow::before,
.dark-mode .ninjadash-top-dropdown .ninjadash-top-dropdown__title {
  background-color: #323541;
}

.dark-mode .ant-picker-range-arrow {
  background: linear-gradient(135deg, transparent 40%, #323541 40%);
}

.dark-mode .ant-btn.ant-btn-white,
.dark-mode .ant-btn.ant-btn-dark,
.dark-mode .ant-btn.ant-btn-dashed,
.dark-mode .RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button,
.dark-mode .ant-popover-inner .ant-popover-inner-content a,
.dark-mode .pop-confirm .ant-btn,
.dark-mode .ant-pagination-disabled .ant-pagination-item-link,
.dark-mode .ant-pagination-disabled:hover .ant-pagination-item-link,
.dark-mode .ant-pagination .ant-pagination-item a,
.dark-mode .ant-notification-notice-description,
.dark-mode .ant-notification-notice-close-x svg,
.dark-mode .ant-modal-close-x svg,
.dark-mode .ant-drawer-body,
.dark-mode .ant-modal-body,
.dark-mode .ant-modal-confirm-body .ant-modal-confirm-content,
.dark-mode .ant-form-item-control-input .ant-picker,
.dark-mode .ant-select-arrow svg,
.dark-mode .ant-picker-range-separator svg,
.dark-mode .ant-picker-suffix svg,
.dark-mode .ninjadash-searchbar .ant-input,
.dark-mode .ant-picker-cell.ant-picker-cell-in-view,
.dark-mode .ninjadash-task-details .ant-modal-header .ant-modal-title .sub-text,
.dark-mode .ant-popover-message-title {
  color: #a4a5aa;
}

.dark-mode .ant-picker-cell {
  color: #a4a5aa25;
}

.dark-mode .ninjadash-top-dropdown__title,
.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item__title,
.dark-mode .ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption h1,
.dark-mode .ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover figcaption h1,
.dark-mode .ninjadash-task-details .ant-modal-header .ant-modal-title h4,
.dark-mode .ant-modal-title,
.dark-mode .ant-modal-confirm-body .ant-modal-confirm-title,
.dark-mode .ant-drawer-title,
.dark-mode .ant-form-item-label>label,
.dark-mode .ant-picker-header button,
.dark-mode .ant-notification-notice-message {
  color: #fff;
}

.dark-mode .ant-btn.ant-btn-white:hover {
  color: #8231D3;
}

.dark-mode .ninjadash-task-details .ant-modal-body .ninjadash-checklist-tasks .ant-checkbox-wrapper .ant-checkbox-inner,
.dark-mode .ant-btn.ant-btn-dark,
.dark-mode .ant-btn.ant-btn-white.btn-outlined,
.dark-mode .pop-confirm .ant-btn,
.dark-mode .ant-pagination .ant-pagination-prev,
.dark-mode .ant-pagination .ant-pagination-next,
.dark-mode .ant-pagination .ant-pagination-jump-prev,
.dark-mode .ant-pagination .ant-pagination-jump-next,
.dark-mode .ant-pagination .ant-pagination-item,
.dark-mode .ant-pagination-options-quick-jumper input,
.dark-mode .ant-modal-header,
.dark-mode .ant-modal-footer,
.dark-mode .ant-pagination .ant-pagination-options .ant-select-selector,
.dark-mode .ant-input-group-addon,
.dark-mode .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.dark-mode .ant-form-item-control-input .ant-picker,
.dark-mode .ant-picker-header,
.dark-mode .ant-picker-panel-container .ant-picker-panel,
.dark-mode .ant-drawer-header,
.dark-mode .ant-input {
  border-color: #323541 !important;
}

.dark-mode .ant-pagination .ant-pagination-prev,
.dark-mode .ant-pagination .ant-pagination-next,
.dark-mode .ant-pagination .ant-pagination-jump-prev,
.dark-mode .ant-pagination .ant-pagination-jump-next,
.dark-mode .ant-pagination .ant-pagination-item,
.dark-mode .ant-pagination .ant-pagination-options .ant-select-selector {
  border: 1px solid #282B37 !important;
  background-color: #fff;
}

.dark-mode .ant-btn.ant-raised.ant-btn-white,
.dark-mode .ant-badge-dot,
.dark-mode .ant-badge-count {
  box-shadow: 0 5px 10px #8c90a410;
}

.dark-mode .ant-btn.ant-btn-dashed {
  border-color: #494b55;
}

.dark-mode .ninjadash-navbar-menu {
  margin-left: 20px;
}

/* Top Menu */

.ninjadash-topmenu .ninjadash-blog-details .ninjadash-share-links {
  margin-left: 1000px !important;
}

/* Auth0 style */

.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: 32px !important;
  margin: 0 0 3px !important;
}

.auth0-lock.auth0-lock .auth0-lock-name {
  font-size: 16px !important;
}
/* misc style */

.mr-10 {
  margin-right: 10px;
}
.ml-30 {
  margin-left: 30px;
}

.mt-50 {
  margin-top: 50px;
}
.ninjadash-ticket-date-info__text {
  margin-bottom: 15px;
}

.idCard .front_id_img, .selfi_id_img {
  object-fit: cover;
  margin-left: 5px;
}
.idCard .front_id_img {
  width: 78px;
  min-height: 48px;
  max-height: 48px;
}
.idCard .selfi_id_img {
  width: 48px;
  min-height: 60px;
  max-height: 60px;
}


.Idmodalbox .front_id_img, .back_id_img, .selfi_id_img {
  object-fit: cover;
  margin-left: 5px;
}
.Idmodalbox .doc_upld_guest {
  width: 200px !important;
  min-height: 123px;
  max-height: 123px;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
}
.Idmodalbox .selfi_img {
  min-height: 250px;
  max-height: 250px;
  width: 200px !important;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
}
.addnewbtn  .btn-align {
  display:flex;
  justify-content: start;
  align-items: flex-end;
  padding-bottom: 6px;
}
.addnewbtn  .btn-align button {
  height: 48px;
}
button.ant-btn-dark {
  font-weight: 700;
}
.switch-toggle {
  height: 22px !important;
}
.ant-switch-handle{
  width: 16px;
  height: 16px;
  top: 3px;
}
.ant-switch-checked .ant-switch-handle{
  left: calc(100% - 16px - 3px);
}
.upload-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px 0px !important;
  max-width: 95px;
}
.upload-section .photo-upload  {
  margin-bottom: 5px !important;
  margin-left: -20px;
}
.upload-section .photo-upload img {
  width:92px;
  height: 92px;
  object-fit: cover;
}
.upload-section span.require {
  padding: 0;
  position: relative;
}
.upload-section span.require:before {
   display: inline-block;
    margin-right: 0px;
    color: #ff4d4f;
    font-size: 15px;
    font-family: Work Sans, sans-serif;
    line-height: 1;
    content: '*';
}
.upload-section span.status {
 text-align: center;
 cursor: pointer;
 background:none;
}
.table-responsive .ant-table-content .ant-table-cell:last-child {
  text-align: left;
}

/* .spinLoader {
  display: block;
  position: absolute;
  width: calc(100% - 250px);
  height: calc(100% - 200px);
}
.spinLoader .ant-spin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
} */

.spinLoader {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
  z-index: 99999;
  top:0;
  left:0;
}
.spinLoader .ant-spin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.readonlyInput[readOnly] {
  border-color: rgb(227, 230, 239) !important;
  cursor-pointer:error;
  opacity: 0.8;
  -webkit-appearance: none;
}
.ant-table .ant-table-cell .user-info figure {
  margin: 0 8px 0 0 !important;
}
.tabsHeader button {
  min-height: 44px;
}

.has-breadcrumb {
  padding-top: 0 !important;
}
.has-breadcrumb .ant-breadcrumb {
    padding-bottom: 15px !important;
}

.ant-picker-status-success {
  border-color: rgb(1, 184, 26) !important;
}
.ant-picker-status-error{
  background-color: #fff !important;
  border-color: #FF0F0F !important;
}
.ant-select-status-success.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
  border-color: rgb(1, 184, 26) !important;
}
.ant-input-affix-wrapper.wifi { padding: 12px 20px !important;}
.ant-table .user-info img {    
  width: inherit !important;
  min-width: 55px;
  max-width: 55px;
  max-height: 39px;
  min-height: 39px;
  background:rgb(248, 249, 251);
  object-fit: cover;
  border-radius: 4px;
}
.ant-input-affix-wrapper-status-success:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper{
  border-color: rgb(1, 184, 26) !important;
}

.ant-avatar{
  min-width: 41px;
  max-width: 41px;
  min-height: 41px;
}

.ant-avatar-image{
  min-width: 41px;
  max-width: 41px;
  min-height: 41px;
  border: 2px solid rgba(255, 192, 98, 1);
 
}
.welcome {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.settings {
    color: #fff !important;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-top: 20px;
    margin-left: 29px;
    display: flex;
    align-items: center;
}

.settings img {
  margin-right: 7px;
}

.autoprofile {
  display: flex;
  justify-content: start !important;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 20px;
}
.ninjadash-nav-actions__author--name {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}
.pageheader {
   width:100%;
   padding-top: 15px;
}
.pageheader .etAjVQ {
  background: none !important;
}
.pageheader .ant-page-header {
  background: none !important;
  padding: 0px 0px 15px !important;
}
.pageheader .ant-menu
{
  width: 50%;
}
.pageheader .ant-menu .ant-menu-item {
  padding: 0 30px;
  position: relative;
}
.pageheader .ant-menu .ant-menu-item:first-child:hover::after {
  content: '';
  left: 0;
  position: absolute;
  bottom: -25px;
  background: rgba(255, 248, 239, 1);
  width: 38px;
  height: 25px;
  border-radius: 24px 0 0;
}
.ant-menu-horizontal {
  border: none;
}

.ant-menu-horizontal .ant-menu-item::after {
  border: none !important;
}
.ant-menu-item-selected-tab {
  background: rgba(255, 248, 239, 1);
  border-radius: 16px 16px 0 0;
  color: rgba(37, 33, 30, 1) !important;
}
.pageheader .ant-menu-item:active, 
.pageheader .ant-menu-item:hover,
.pageheader .ant-menu-item:visited  {
  background: transparent !important;
  border-radius: 16px 16px 0 0 !important;
  color: #fff !important;
}

.pageheader .ant-menu-item.ant-menu-item-selected-tab:hover{
  background: #fef8ef !important;
  color: #000;
}

.ant-menu-item-selected-tab span {
  color: rgba(37, 33, 30, 1) !important;
  font-weight: 700;
}
.ant-menu-item.deactive {
  color: #ffffff !important;
  font-weight: 700;
  border-radius: 16px 16px 0 0 !important;
}
.borderRadius {
  border-radius: 0px 24px 24px 24px;
  background: rgba(255, 248, 239, 1);
  overflow: auto;
}
.borderRadius2 {
  border-radius: 24px;
  background: rgba(255, 248, 239, 1);
  overflow: auto;
}
.borderRadius .ant-card-body {
  padding: 0 !important; 
}
.borderRadius2 .ant-card-body {
  padding: 0 !important; 
}
.bg-none {
  background: none !important;
}
.ant-page-header-heading button[outlined="1"].ant-btn {
  height: 44px !important;
  color: #fff!important;
  font-weight: 700;
}
.ant-page-header-heading button[outlined="1"].ant-btn a {
  height: 44px !important;
  color: #fff!important;
  font-weight: 700 !important;
}
.ant-page-header-heading button[outlined="0"].ant-btn {
  color: rgb(37, 33, 30) !important;
  font-weight: 700;
}

.ant-page-header-heading button[outlined="0"].ant-btn a {
  color: rgb(37, 33, 30) !important;
  font-weight: 700 !important;
}
.trash_icon{
  background: #fff;
  padding: 0 !important;
  height: 44px;
  width: 44px;
}
.ant-page-header-heading button[outlined="0"].ant-btn a.trash_button{
  color: #fff !important;
}
.trash_icon .trash_button img{
  width: 22px;
  height: 22px;
}
.ant-table {
  background: none !important;
  font-size: 14px;
  margin-bottom: 5px;
}
.ant-table table {
  position: relative;
  border-spacing: 0px 3px;
  z-index: 0;
}
.ant-table-container table > tbody > tr td {
  white-space: normal !important;
  padding: 20px 15px 15px !important;
  position: relative;
  z-index: 2;

}

.ant-table-thead > tr > th {
  padding: 20px 16px!important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 45px !important;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  padding-right: 45px !important;
}
.ant-table-container table > tbody > tr td:first-child {
  padding-left: 45px !important;
}
.ant-table-container table > tbody > tr td:last-child {
  padding-right: 45px !important;
}



.ant-table-container table > tbody > tr {
  position: relative;
}

.ant-table-container table > tbody > tr::after {
  background: rgba(37, 33, 30, 0.06);
  content: ''; 
  position: absolute;
  left: 25px;
  top: 5px;
  bottom: 0;
  width: 98%;
  z-index: 0;
  height: 100%;
  border-radius: 7px; 
  border: 1px solid transparent;
}
.ant-table-container table > tbody > tr:hover::after {
  background: rgba(37, 33, 30, 0.02);
  content: '';
  position: absolute;
  left: 25px;
  width: 98%;
  z-index: 1;
  height: 100%;
  top: 5px;
  border-radius: 7px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
 
}
.ant-table-container table > tbody > tr:hover td{
  background:none !important;
}
.ant-table-container table > tbody > tr td.ant-table-column-sort {
  position: relative;
  z-index: 0;
  background: none;
}

.ant-table table .ant-table-container::before {
  display: none;
}
.ant-table table .ant-table-thead > tr > th {
  color: rgba(37, 33, 30, 1);
  font-weight: 600;
  background: rgba(255, 248, 239, 1) !important;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.14); */
  position: sticky;
  top:0;
  z-index: 9;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.ant-table table .ant-table-thead {
  position:relative;
}
.ant-table table .ant-table-thead:after {
  position:absolute;
  bottom:0;
  content: '';
  left: 0;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  box-shadow: 0 0px 1px 1px rgba(0, 0, 0, 0.08);
}
.ant-table-column-sorters .ant-table-column-sorter-inner {
  position: relative;
}
.ant-table-column-sorters .ant-table-column-sorter-inner .ant-table-column-sorter-up {
  background-image: url('../img/short_up.svg');
  width: 10px;
  height: 6px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 5px;
}

.ant-table-column-sorters .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  background-image: url('../img/short_down.svg');
  width: 10px;
  height: 6px;
  background-repeat: no-repeat;
  background-size: 100%;
}
.ant-table-column-sorters .ant-table-column-sorter-inner .ant-table-column-sorter-up svg, .ant-table-column-sorters .ant-table-column-sorter-inner .ant-table-column-sorter-down svg {
display:none;
}
.mainBlock {
  height: auto !important;
  min-height: auto !important;
  padding-top: 100px;
  padding-bottom: 15px;
}
.reportdamgeiframe {
  width: 100%;
  min-height: 400px;
  border:none;
}

/******************Rahul CSS*********************/
.image_upload_box{
  display: flex;
  justify-content: center;
  background-color: #fef8ef;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.08);
  margin: 20px 0 5px;
  position: relative;
}
.image_upload_box .status.error{
  position: absolute;
  right: 0;
  top: 0;
}
.image_upload_box .info{
  position: absolute;
  top: -30px;
  left: -4px;
}
.image_upload_box .upload-section .photo-upload img{
  width: 100%;
  height: 161px;
  border-radius: 10px !important;
  object-fit: contain;
}
.image_upload_box .upload-section {
  max-width: none;
  width: 100%;
  margin-bottom: 0 !important;
}
.image_upload_box .upload-section .photo-upload img.image_uploaded{
  object-fit: cover;
  width: 100%;
}
.image_upload_box .upload-section .photo-upload{
  max-width: none;
  justify-content: center;
  text-align: center;
  margin-left: 0;
  margin-bottom: 0 !important;
}
.image_upload_box .upload-section .photo-upload figcaption{
  width: 100%;
  margin-left: 0;
}
.image_upload_box .upload-section h1 {
  font-weight: 700 !important;
  font-size: 14px !important;
}
.image_upload_box .upload-section span.image_label{
  position: static;
}
.image_upload_box .upload-section .photo-upload .ant-upload-select{
  left: auto;
  right: -20px;
  cursor: pointer;
  bottom: -15px;
}
.ant-card{
  background: #FFF8EF;
}
.page_header_custom .etAjVQ{
  background-color: transparent;
  margin: 0 30px;
}
.page_header_custom .etAjVQ .ant-page-header{
  background-color: #FFF8EF;
  border-radius: 10px;
}
.kFVogO .upload-section .photo-upload figcaption .info h1{
  font-weight: 700;
}
.ant-picker {
  background-color: #f4eee6 !important;
  border: 2px solid rgba(0, 0, 0, 0.08) !important;
}
.custom_select_selector .ant-select-selector{
  background-color: #f4eee6 !important;
}
/* .ant-select-single.custom_select_selector .ant-select-selector{
  border: 2px solid #000 !important;
} */
.custom_select_selector .ant-select-selection-placeholder{
  color: #000;
  font-weight: 400;
  font-size: 15px;
}
.ant-picker input{
  background-color: #f4eee6 !important;
}
.ant-picker:hover, .ant-picker-focused{
  border-width: 2px;
}
.ant-input , .ant-input-password {
  background-color: #f4eee6 !important;
  border: 2px solid rgba(0, 0, 0, 0.08) !important;
}
.ant-upload{
  background-color: #f4eee6 !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;  
}
.ant-upload span span{
  color: rgb(64, 64, 64) !important;
}
.ant-input.ant-input-status-error{
  border-color: #FF0F0F !important;
}
.ant-input-password .ant-input {
  border: none !important;
}
.ant-input::placeholder , .ant-picker-input input::placeholder,input#contact_checkin_date::placeholder {
  color: #000000;
  font-weight: 400;
}
.date_time_pcikr input::placeholder{
  color: #000000 !important;
  font-weight: 400 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: none;
}
.custom_cke_editor_box .sun-editor .se-btn-tray , .custom_cke_editor_box .se-wrapper-inner{
  background-color: #f4eee6;
}
.custom_cke_editor_box .sun-editor .se-wrapper .se-placeholder{
  color: #000;
  font-family: "Work Sans", sans-serif !important;
}
.custom_cke_editor_box .sun-editor .se-resizing-bar{
  background-color: #f4eee6;
}
.ant-form-item-label > label::after{
  display: none;
}
.image_listing_url .ant-form-item.ant-form-item-has-error .check_in_url_input{
  margin-bottom: 0;
}
.image_listing_url .ant-form-item.ant-form-item-has-error .check_in_url_input .ant-input.ant-input-status-error{
  border-color: rgba(0, 0, 0, 0.08) !important;
}
.image_listing_url .ant-form-item.ant-form-item-has-success .check_in_url_input .ant-input.ant-input-status-success{
  border-color: rgba(0, 0, 0, 0.08) !important;
}
.image_listing_url .ant-form-item .ant-form-item-label{
  width: 80%;
  margin: 0 auto;
}
.image_listing_url .ant-form-item.ant-form-item-has-error .ant-form-item-explain-error{
  margin-left: 16%;
}
.check_in_url_input{
  width: 80%;
  margin: 10px auto 25px;
  display: flex;
  align-items: center;
  /* user-select: all; */
}
.check_in_url_input .url_box{
  border: 2px solid rgba(0, 0, 0, 0.08);
  padding: 12px 0px 12px 20px;
  background-color: #f4eee6;
  border-radius: 10px;
  color: #000;
  font-weight: 400;
  width: 100%;
}
.check_in_url_input input{
  border-left: none !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 12px 20px 12px 0;
}
.check_in_url_input input:focus, .check_in_url_input input:focus-visible{
  box-shadow: none !important;
}
.ant-switch{
  min-width: 55px;
}
.bTQATK .custom_input_style .ant-form-item-row .ant-select-single .ant-select-selector, .bTQATK .custom_input_style .ant-form-item .ant-select-single .ant-select-selector , .kEjklD .custom_input_style .ant-form-item .ant-select-single .ant-select-selector , .kEjklD .custom_input_style .ant-form-item-row .ant-select-single .ant-select-selector, .ant-form-item .ant-select-single .ant-select-selector{
  background-color: #f4eee6 !important;
  border-color: rgba(0, 0, 0, 0.08) !important;
  border: 2px solid rgba(0, 0, 0, 0.08) !important;
}
.bTQATK .custom_input_style .ant-form-item-row .ant-select-single .ant-select-selector .ant-select-selection-placeholder , .kEjklD .custom_input_style .ant-form-item-row .ant-select-single .ant-select-selector .ant-select-selection-placeholder , .ant-form-item-row .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  color: #000000;
  font-weight: 400;
  font-size: 15px;
}
.ant-form-item-label > label{
  font-size: 14px;
}
.ant-page-header-heading .secondary-btn{
  color: #000;
}
/* .ant-page-header-heading .secondary_btn button[outlined="1"].ant-btn a{
  color: #000 !important;
  display: flex;
  align-items: center;
} */
.ant-modal-mask{
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-modal-header , .login_form_section, .ant-modal-content{
  background-color: #FFF8EF;
}
.signin_bottom_box{
  background-color: #FFF8EF !important;
}
.login_btn{
  font-size: 14px;
  background: rgb(10, 10, 10);
  border-width: 0px;
  border-style: solid;
  color: rgb(255, 255, 255);
  border-radius: 40px !important;
}
.login_btn:hover{
  background: rgb(39, 37, 37);
  color: #fff;
}
.table-responsive .ant-table-content .ant-table-cell .coverage a.common_action_btn{
  width: 85px;
  text-align: center;
}
a.common_urlLink{
  color: #000 !important;
}
.login_btn:hover, .login_btn:focus, .login_btn:active{
  background: #000;
  color: #fff;
}
.ant-form-item.custom_input_style .ant-select-single .ant-select-selector , .ant-form-item.custom_input_style .ant-form-item-row .ant-select-single .ant-select-selector{
  border-color: rgba(0, 0, 0, 0.08) !important;
}
.ant-select-dropdown{
  background-color: #25211e;
  box-shadow: 0 8px 15px #9299b815 !important;
}
.rc-virtual-list-scrollbar-thumb{
  background-color: #ffc05c !important;
}
.ant-select-dropdown .ant-empty-description{
  color: #fff;
}
.ant-form-item.ant-form-item-has-success .ant-input{
  border-color: rgb(1, 184, 26) !important;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper{
  border-color: #FF0F0F !important;
}
.global_search_filter{
  background-color: #FFF8EF;
  padding: 24px 20px 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.global_search_filter .ant-tag{
  font-weight: 700;
}
.global_search_filter .filter-is-title{
  font-weight: 700;
  color: #000;
  margin-top: 3px;
}
.common_fiter_table{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-card.common_fiter_table , .ant-card.borderRadius2{
  margin-bottom: 0 !important;
}
.global_search_filter.upcoming_filter_tab_btn{
  border-top-left-radius: 0;
}
.pageheader .ant-menu-horizontal > .ant-menu-item:hover, .pageheader .ant-menu-horizontal > .ant-menu-item-active, .pageheader .ant-menu-horizontal > .pageheader .ant-menu-submenu .ant-menu-submenu-title:hover{
  background:#fff8ef;
}
.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content{
  color: #000;
}
.ant-form-item.ant-form-item-has-success .ant-input.readonlyInput{
  border-color: rgba(0, 0, 0, 0.08) !important;
}
.ant-checkbox-inner{
  width: 22px;
  height: 22px;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox']{
  width: 20px;
  height: 20px;
}
.ant-checkbox-inner::after{
  width: 7px;
  height: 12px;
}
.showImageError.host{
  margin-left: 10px;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn+.ant-btn{
  border-radius: 60px;
  border-color: #ffc062;
  background: #ffc062;
  color: #000;
  font-weight: 700;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn.ant-btn-default{
  font-size: 14px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 40px;
  padding: 0px 20.5px;
  height: 44px;
  box-shadow: 0px 0px;
  background: #25211e;
  color: #fff;
  border: 1px solid rgb(255, 192, 98);
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn.ant-btn-primary{
  font-size: 14px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 40px;
  padding: 0px 20.5px;
  min-width: 90px;
  height: 44px;
  box-shadow: 0px 0px;
  background: #ffc062;
  color: #fff;
  border: 1px solid rgb(255, 192, 98);
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn:hover, .ant-modal-confirm .ant-modal-confirm-btns .ant-btn:focus{
  border-radius: 60px;
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
  width: calc(100% + 0px);
}
.delete_icon_btn button, .partner_delete_icon_btn button{
  padding: 0 15px;
}
.delete_icon_btn button svg, .partner_delete_icon_btn button svg{
  width: 20px;
  height: 20px;
}
.ant-modal-body button{
  font-weight: 700;
}
.not_found_page_sec h1.error-text , .not_found_page_sec p{
  color: #fff !important;
}
.not_found_page_sec .not_found_innerBox{
  min-height: calc(100vh - 20px);
}
.fill_button_primary{
  font-weight: 700;
  color: #000;
}
.fill_button_primary span{
  color: #000;
}
.custom_ticket-date-info__text{
  margin-bottom: 0;
}
.require_checkin_span{
  line-height: normal;
}
.ant-pagination-item-ellipsis{
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.resv_details_url_title{
  word-wrap: break-word;
}
.lh-normal{
  line-height: normal;
}
.kCkGhe .ant-card h4{
  color: #000;
}
.ant-modal-wrap .modal-lg.reportdamagemodal{
  width: 900px !important;
}
.ant-modal.update_profile_modal .upload-section .photo-upload{
  margin-left: 0;
}
.ant-modal.update_profile_modal .ant-modal-body{
  padding-top: 0;
}
.ant-modal.update_profile_modal .photo-upload .ant-upload-select{
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  left: 85px;
  bottom: 5px;
  z-index: 10;
  background-color: rgb(255, 255, 255);
}
.ant-modal.update_profile_modal .photo-upload .ant-upload-select span {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: -1;
  background-color: rgb(255, 192, 98);
}
.ant-modal.update_profile_modal .photo-upload .ant-upload-select svg{
  color: #fff;
}
.ant-modal.update_profile_modal .upload-section .photo-upload .info h1{
  font-size: 15px;
}
.user_profile_box{
  position: relative;
  background: transparent;
  border-radius: 70px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 3px 0;
  z-index: 0;
}
.user_profile_box .ninjadash-nav-actions__author--name{
  width: 100px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: normal;
}
.user_profile_box .ninjadash-nav-action-link{
  align-items: center;
}
.user_profile_box .user_profile_option{
  padding: 10px 0 26px;
  position: absolute;
  display: none;
  left: -1px;
  top: -126px;
  background: #25211e;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 180px;
  z-index: -1;
  transition: all 0.4s ease;
}
.pms-user_profile_box.user_profile_box .user_profile_option{
  top: -165px;
}
.payout-setting-profile-box.user_profile_box .user_profile_option{
  top: -195px;
}
.user_profile_box .user_profile_option a{
  margin: 0;
  padding: 6px 12px;
  font-weight: 500;
}
.user_profile_box .user_profile_option a:last-child{
  padding-bottom: 20px;
}
.user_profile_box .user_profile_option a img{
  /* filter: invert(1); */
  width: 16px;
}
.user_profile_box .user_profile_option a span{
  /* color: #000; */
}
.user_profile_box:hover .user_profile_option{
  display: block;
  transition: all 0.4s ease;
}
.mobile_view_serach{
  position: fixed;
  right: 40px;
  bottom: 35px;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #FFED49;
  border: 2px solid #000;
  z-index: 2;
  border-radius: 50%;
  padding: 10px;
}
.upload-section .photo-upload .user_profile_image{
  border-radius: 50%;
}
.profile_update_section{
  max-width: none;
  margin-top: 15px;
}
.ant-modal.update_profile_modal .profile_update_section .photo-upload .ant-upload-select{
  left: auto;
  right: 65px;
}
.ant-modal.update_profile_modal .photo-upload .ant-upload-select.ant-upload-img-error{
  bottom: 28px;
}
.delete_icon_btn, .partner_delete_icon_btn{
  position: relative;
}
.delete_icon_btn .ant-btn-error{
  position: absolute;
  top: 34px;
}
aside.ant-layout-sider{
  flex: 0 0 220px !important;
  max-width: 220px !important;
  min-width: 220px !important;
}
aside.ant-layout-sider .custom-scrollbar div:first-child{
  position: static !important;
  overflow: unset!important;
  margin-right: 0!important;
  margin-bottom: 0!important;
}
aside.ant-layout-sider .profile_menu_box{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(100vh - 180px);
}

.ant-table-container table>tbody>tr:after{
  background:#f4ebe3;
}

.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu-title {
  transition: unset;
}
.ant-btn.form-cancel-btn{
  background: #25211e;
  margin-left: 10px;
}
.horizontal_cust_tablist.listing_tab_menu  li span{
  display: inline-block;
  padding-bottom: 22px;
}
.horizontal_cust_tablist.listing_tab_menu .locals_tab_btn{
  /* display: none; */
}
.add_locals_hide_btn{
  display: none;
}
.common_fiter_table.listin_card{
  padding: 0 20px 20px;
  overflow: unset;
}
.admin_welcome_box{
  display: flex;
  flex-direction: column;
}
.ant-table-container table>tbody>tr td.ant-table-column-sort{
  z-index: 2;
}
.project-modal video{
  max-height: 350px;
}
.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content{
  padding-left: 0;
  color: #f9c268;
}
.top_header_sticky{

  position: sticky;

  top: 0;

  z-index: 99;

  background: #25211e;

}
.top_header_sticky.top_sticky_header_radius::before{
  content: '';
  position: absolute;
  bottom: -9px;
  left: 0px;
  background-color: #fef8ef;
  z-index: 0;
  width: 100%;
  height: 18px;
  border-radius: 10px 10px 0 0;
}

.ant-menu-inline.ant-menu-root .ant-menu-item{

  transition: none;

}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover{

  transition: none;

}
.customEditor{
  background-color: #fef8ef;
  position: relative;
  border: 1px solid #dadada;
}
.customEditor .quill .ql-formats button{
  height: auto !important;
}
.customEditor .ql-snow .ql-tooltip.ql-flip{
  left: 0px !important;
  top: 55px !important;
  border-radius: 5px;
}
.customEditor .code_preview_btn{
  position: absolute;
  right: 546px;
  top: 10px;
  height: auto !important;
  background: transparent;
  border: none;
  cursor: pointer;
}
.customEditor .ql-container{
    max-height: 350px;
    min-height: 350px;
    overflow-y: auto;
    position: static;
}
.customEditor .ql-container.ql-snow , .customEditor .ql-toolbar{
  border: none;
}
.customEditor .ql-toolbar.ql-snow{
  border-bottom: 1px solid #dadada;
}
.customEditor .code_preview_btn img{
  width: 18px;
}
.customEditor .ql-container .ql-editor ul li , .customEditor .ql-container .ql-editor ol li{
  color: #404040;
}
.html_preview_box{
  white-space: pre-wrap; 
  padding: 12px 15px; 
  margin-top: 10px;
  word-break: break-all;
}

.map_image_sec .photo-upload{

  margin-bottom: 0 !important;

}

.map_image_sec .photo-upload figcaption{

  margin-left: 0 !important;

  border: 2px solid rgba(0, 0, 0, 0.08);

  border-radius: 6px;

}

.map_image_sec .photo-upload img{
  border-radius: 6px !important;
  min-width: 50px;
  max-width: 50px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.map_image_sec .photo-upload .ant-upload-select{

  top: -6px;

  bottom: auto !important;

  left: 42px !important;

  width: 20px !important;

  height: 20px !important;

}

.map_image_sec .photo-upload .ant-upload-select span{

  width: 15px !important;

  height: 15px !important;

}

.map_image_sec a {
  display: inline-block;
}

.map_image_sec a img.listingDetailsImageWidth{
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 6px;
}

/* .top_header_sticky::before {

  content: "";

  position: absolute;

  background-color: transparent;

  bottom: -50px;

  height: 50px;

  width: 25px;

  border-top-left-radius: 10px;

  box-shadow: 0 -25px 0 0 #25211e;

  left: 0;

}

.top_header_sticky::after {

  content: "";

  position: absolute;

  background-color: transparent;

  bottom: -50px;

  height: 50px;

  width: 25px;

  border-top-left-radius: 10px;

  box-shadow: 0 -25px 0 0 #25211e;

} */


.early_check_input .ant-input-affix-wrapper, .early_check_input .ant-input-number-affix-wrapper{
  background: #f3eee6 !important;
  border: 2px solid rgba(0, 0, 0, 0.08) !important;
}
.early_check_input .ant-input-number-affix-wrapper{
  width: 100%;
}
.early_check_input .ant-input-number-input-wrap , .early_check_input .ant-input-number-affix-wrapper .ant-input-number-handler-wrap{
  background: #f3eee6 !important;
}
.early_check_input .ant-input-number-affix-wrapper .ant-input-number-input-wrap input{
  background-color: #f3eee6 !important;
}
.ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-focused{
  box-shadow: none;
}
.early_check_input .ant-input-affix-wrapper input , .early_check_input .ant-input-number-affix-wrapper .ant-input-number{
  border: none !important;
  padding-left: 0 !important;
  line-height: 18px;
}
.early_check_input .ant-input-prefix{
  margin-right: 0 !important;
}
.early_check_input .ant-input-prefix svg{
  color: #404040;
  width: 20px;
  height: 20px;
}
.user_profile_option.new_menu_item_add{
  top: -160px;
}

input.upload_img_input[type='file']{
  display: none;
}

.listing_details_list{
  margin-bottom: 0;
}

.listing_details_photo_anchor{
  display: inline-block;
  width: 100%;
}

.detail_listing_address{
  white-space: pre-line;
}
.map-details-container.listing_map_details{
  padding-top: 5px;
  border-bottom: 1px solid #92908f;
  padding-bottom: 5px;
}
.map-details-container.lable_map_detalis{
  border-bottom: 1px solid #92908f;
}
.requiredFiled{
  color: red;
}
.earlyCheckinPopup{
  /* border: 1px solid #ccc; */
  padding: 15px;
}
.otherDetails p, .hostPayout p {
  margin: 10px 0;
}

.line {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}
.listingDetailsImageWidth{
  width: 50%;
}
.cardTitle {
  border-color: rgb(227, 230, 239) !important;
  cursor-pointer:error;
  opacity: 0.8;
  -webkit-appearance: none;
}
.map-details-container span.value{
  word-break: break-word;
}
.ant-col img {
  max-width: 100%;
  height: auto;
}

.custom_editor_span_text p , .cms_desc_editor_span span p{
  margin-bottom: 3px;
  min-height: 12px;
}
.custom_editor_span_text ul li , .custom_editor_span_text ol li {
  color: #404040;;
}
.cms_desc_editor_span span , .cms_desc_editor_span span p , .color_black{
  color: #000;
}
.custom_editor_span_text ul{
  padding-left: 40px;
}
.custom_editor_span_text ul li{
  list-style: disc;
}
.Idmodalbox.earlyCheckinPopup{
  padding: 0;
}
.custom_editor_span_text h1{
  font-size: 24px;
  margin-bottom: 0px;
}
.custom_editor_span_text h2{
  font-size: 18px;
  margin-bottom: 0px;
}
.Idmodalbox.earlyCheckinPopup .hostPayout p, .Idmodalbox.earlyCheckinPopup .line, .Idmodalbox.earlyCheckinPopup .otherDetails p{
  margin-top: 0;
  margin-bottom: 3px;
  line-height: 20px;
  color: #000;
}
.ant-modal-close-x{
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.common_custom_table_th{
  display: block;
}
.coverage-table{
  /* margin-left: -25px;
  overflow-y: hidden; */
  /* background-color: lightcoral; */
}
/* .coverage-detail-table{
  margin-left: -10px;
  overflow-y: hidden;
} */
.partner-detail-table{
  margin-left: -22px;
  overflow-y: hidden;
}

.common_label_wrap_text .ant-form-item-label, .partner_common_label .ant-form-item-label{
  overflow: unset;
  white-space: unset;
}
.common_label_wrap_text .ant-form-item-label label{
  min-height: 65px;
  align-items: flex-end;
}

.ant-input-number .ant-input-number-handler-wrap, .ant-input-number-focused .ant-input-number-handler-wrap{
  opacity: 1;
}
.coverage_row_sec ul{
  display: flex;
}
.coverage_row_sec ul li{
  flex: 1;
}
.coverage_row_sec ul li.partner_delete_icon_btn {
  padding-left: 5px;
}
.coverage_row_sec ul li.partner_delete_icon_btn button{
  top: 8px;
}
.coverage_row_sec ul:first-child li.partner_delete_icon_btn button{
  top: 36px;
}
.coverage_row_sec ul li .ant-input-affix-wrapper , .coverage_row_sec ul li .ant-input{
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.coverage_row_sec ul li:nth-child(3){
  max-width: 100px;
}
.coverage_row_sec ul li:nth-child(7){
  min-width: 205px;
}
.ant-card.host_userdetails_card{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-card.host_userdetails_card{
  margin-bottom: 0 !important;
}
.coverage_table_card .ant-card-body{
  padding: 0px 0 25px!important;
}
.upload-section.details_upload_section{
  max-width: unset;
}
.upload-section.details_upload_section .status_delete_img{
  position: absolute;
  left: 0;
  top: 30px;
}
.upload-section.details_upload_section h1{
  font-weight: 700 !important;
  font-size: 14px !important;
}
.upload-section .photo-upload.details_photo_upload{
  margin-left: 0;
}
.coverage_table_card_action{
  margin: 0 !important;
}
.coverage_table_card  .ant-table-tbody tr td{
  border-bottom: none;
}
.contractpdfbtn{
  margin-left: 10px;
  margin-top: 10px;
}
.waiver_label_box label::before{
  display: none !important;
}
.waiver_label_box label span{
  padding-left: 14px;
}
.waiver_label_box label span::before{
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 15px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  position: absolute;
  left: 0;
}
.show_agreement_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.show_agreement_box .contractpdfbtn{
  margin-top: 0;
  height: 36px !important;
  padding: 12px;
}
.show_agreement_box .contractpdfbtn svg{
  width: 16px;
  height: 16px;
}
.provider_bodyvariable_box label{
  width: 100%;
}
.provider_bodyvariable_box label .provider_body_veriable{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.provider_bodyvariable_box label .provider_body_veriable a , .ant-modal.select-variable-modal .ant-modal-body ul li a{
  margin-left: 10px;
  color: #000;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
}
.ant-modal.select-variable-modal .ant-modal-body ul li a{
  margin-left: 0;
}
.ant-modal.select-variable-modal .ant-modal-header{
  border-bottom: none;
  padding-bottom: 0;
}
.ant-modal.select-variable-modal .ant-modal-body{
  padding-top: 15px;
}
.ant-modal.select-variable-modal .ant-modal-title{
  font-weight: 600;
  color: #000;
}
.item-approve-btn{
  border-radius: 60px;
  border-color: #ffc062;
  background: rgb(255, 192, 98);
  color: #000;
  font-weight: 700;
}
.ant-modal-confirm .ant-modal-confirm-btns.rejectmodal{
  margin-top: 0px;
  margin-bottom: -58px; 
  padding-bottom: 10px; 
}
.custom-reject-lbl{
  color: black;
  font-weight: 600;
}
.reject-form{
  margin-top: 20px;
}

/*********=================Chatbox Design ============*/
.custom-chatBox-section{}
/*=====================Custom Upsells Item CSS ========================*/
.custom-sell-item{
  display: flex;
  /* margin-bottom: 15px; */
}
.custom-sell-item .ant-input {
  padding-left: 10px;
  padding-right: 10px;
}
.custom-sell-item .ant-input-affix-wrapper.wifi{
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.custom-sell-item .common_label_wrap_text .ant-form-item-label label{
  min-height: 42px;
  align-items: baseline;
}
.upsell-item-price{
  width: 125px;
}
.custom-sell-item .upsell-delete-btn .ant-btn-error{
  top: 10px;
}
.upsell-item-desc, .upsell-item-postpurch{
  width: 300px;
}
.upsell-service-fee{
  width: 135px;
}
.upsell-auto-approve{
  width: 100px;
}
.item-desc{
  padding: 5px 10px;
  margin-bottom: -25px;
}
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon{
  padding-top: 2px;
}

.ant-table-cell.guest-item-status{
  min-width: 230px;
}
.hostPayout .modal-content ul li , .hostPayout .modal-content ol li{
  color: #000;
}
.reject-reason-info{
  display: flex;
  align-items: center;
}
.reject-reason-info .info-logo{
  margin-left: 5px;
}
.listing-question{
  margin-bottom: 10px;
}
.listing-question-h4 h4{
  margin-bottom: 0px;
}
/* .country-code-custom_box .react-tel-input .selected-flag{
  width: 50px;
  padding: 0 0 0 12px;
} */
.country-code-custom_box .react-international-phone-input-container .ant-input{
  border-radius: 0 6px 6px 0 !important;
}
.country-code-custom_box .react-tel-input .flag-dropdown {
  background: transparent;
}
.country-code-custom_box .react-tel-input .country-list .country {
  background: #f4eee6 ;
}
.country-code-custom_box .react-international-phone-input-container .react-international-phone-country-selector-button{
  height: 52px !important;
  padding: 0 0 0 5px; 
  width: 60px;
  background-color: #f4eee6!important;
  border: 1px solid #cacaca !important;
  border-right: none !important;
  border-radius: 6px 0 0 6px !important;
}
.country-code-custom_box .react-international-phone-input-container .react-international-phone-country-selector-dropdown{
  background-color: #f4eee6;
}
.country-code-custom_box .react-international-phone-input-container .react-international-phone-country-selector-dropdown__list-item-country-name{
  font-size: 14px;
  color: #666d92;
}
.chat-area-container {
  position: relative;
}
.chat-area-container::after {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fef8ef;
  content: '';
  top: 0;
  left: -2px;
}
/* Global container styling */
.inbox-container {
  background-color: #2B2B2B;
  /* height: 100vh; */
  padding: 20px;
  margin-top: 30px;
  }
  .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  }
  .guest-list-container, .chat-area-container, .guest-details-container {
  height: calc(100vh - 85px);
  }
  /* Card styling for guest list, chat area, and guest details */
  .guest-list-cards, .chat-area-cards, .guest-details-cards {
  height: 100%;
  border-radius: 0;
  }
  .chat-area-cards .ant-card-body{
  overflow-y: hidden;
  height: 100%;
  }
  .guest-list-cards{
  border-radius: 15px 0 0 15px;
  }
  .guest-details-cards{
  border-radius: 0 15px 15px 0;
  }
  .guest-list-cards .ant-card-body{
  height: 100%;
  overflow-y: hidden;
  padding-right: 8px !important;
  }
  .guest-list-cards .left-card-box{
  height: calc(100% - 115px);
  }
  .guest-list-cards .left-card-box .guest-search-filter button::after {
  display: none;
  }
  .guest-list-cards .left-card-box .guest-search-filter button:hover, .guest-list-cards .left-card-box .guest-search-filter button:focus ,.guest-list-cards .left-card-box .guest-search-filter button span:hover{
  border: none;
  transition: none;
  transform: unset;
  }
  .guest-list-cards .left-card-box .guest-search-filter .serch-input-box{
  position: relative;
  width: 100%;
  }
  .guest-list-cards .left-card-box .guest-search-filter .serch-input-box input{
  padding-right: 75px;
  padding-left: 8px;
  }
  .guest-list-cards .left-card-box .show-more-box{
  background: #fef8ef;
  padding: 20px 0 15px;
  text-align: center;
  }
  .guest-list-cards .left-card-box .show-more-box .show-more-btn{
  border-radius: 40px;
  color: #25211e;
  font-weight: 600;
  background: #edc868;
  border: none;
  padding-top: 5px;
  height: 30px;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 13px;
  }
  .guest-list-cards .left-card-box .show-more-box .show-more-btn:hover{
  background: rgb(255, 192, 98) !important;
  color: #25211e !important;
  }
  .guest-list-cards .left-card-box .show-more-box .hide-show-more{
  display: none;
  }
  .guest-list-cards .left-card-box .guest-search-filter .serch_btn_filter{
  position: absolute;
  right: 8px;
  top: 11px;
  padding: 0px 10px;
  height: 30px;
  border-radius: 40px;
  color: #25211e;
  font-weight: 700;
  background: rgb(255, 192, 98);
  border: none;
  font-size: 13px;
  }
  .guest-list-cards .left-card-box .guest-search-filter .rBox-filterBtn{
  display: flex;
  align-items: center;
  }
  .guest-list-cards .left-card-box .guest-search-filter .rBox-filterBtn .date-filter-btn{
  background: #272525;
  border-radius: 50%;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  border: none;
  }
  .guest-list-cards .left-card-box .guest-search-filter .rBox-filterBtn .date-filter-btn span{
  font-size: 20px;
  }
  .guest-list-cards .left-card-box .guest-search-filter .rBox-filterBtn .date-filter-btn svg{
  fill: #fff;
  }
  .guest-list-cards .left-card-box .guest-search-filter .rBox-filterBtn .serch_btn_filter {
  position: unset;
  }
  .guest-list {
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 15px;
  height: 100%;
  padding-right: 5px;
  }
  .guest-list::-webkit-scrollbar {
  margin: 10px 0;
  width:8px;
  }
  .guest-list::-webkit-scrollbar-track{
  background: transparent;
  }
  .guest-list::-webkit-scrollbar-thumb{
  background: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  }
  .chatBox-container{
  display: flex;
  }
  .guest-list-container{
  min-width: 375px;
  max-width: 375px;
  }
  .guest-list-container .guest-list-loader{
  position: relative;
  }
  .guest-list-container .guest-list-loader .sk-circle{
  left: 50%;
  transform: translateX(-50%);
  }
  .chat-area-container{
  max-width: 100%;
  width: 100%;
  }
  .chat-area-container .chat-area-cards{
  border-radius: 0 15px 15px 0;
  transition: none;
  }
  .chat-area-container.chat-area-border-radius .chat-area-cards{
  border-radius: 0;
  transition: none;
  }
  .chat-area-container .nochat-box{
  min-height: calc(100vh - 135px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  }
  .chat-area-container .nochat-box p{
  font-weight: 600;
  margin-top: 10px;
  }
  /* Guest card styling */
  .guest-list .guest-card {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  margin-bottom: 10px;
  background-color: #f4ebe3;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #333333;
  }
  .guest-list .guest-card.disabled {
  cursor: none;
  }
  .guest-list .guest-card:hover {
  background-color: #EAEAEA;
  }
  .guest-list .guest-card.active {
  background-color: #E0E0E0;
  }
  .guest-profile {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 10px;
  }
  .guest-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  }
  .guest-info {
  flex-grow: 1;
  }
  .guest-info .title-date-box{
  display: flex;
  justify-content: space-between;
  }
  .guest-info .title-date-box strong{
  color: #25211e;
  text-decoration: underline;
  }
  .guest-info .title-date{
  font-size: 12px;
  padding-left: 15px;
  color: #25211e;
  font-weight: 500;
  min-width: 128px;
  }
  .guest-info h6 {
  margin: 0;
  font-weight: bold;
  color: #333333; /* Unified dark text color */
  }
  .guest-info .guest-dates{
  font-weight: 700;
  color: #25211e;
  font-size: 14px;
  }
  .guest-message-preview{
  font-size: 14px;
  font-weight: 500;
  padding-right: 25px;
  }
  /* .guest-info .guest-dates, .guest-info .guest-message-preview {
  font-size: 0.9em;
  color: #666666;
  } */
  /* Chat area styling */
  .chat-area {
  height: calc(100% - 156px);
  }
  .chat-area .chat-header , .guest-profile-detailbox .chat-header{
  background: #f4ebe3;
  border-radius: 15px;
  padding: 6px 15px;
  margin-bottom: 10px;
  min-height: 55px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
  .chat-area .chat-header .custom-hamburgur{
  min-width: 35px;
  width: 35px;
  height: 35px;
  background: #f9c268;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  }
  .chat-area .chat-header h4 , .guest-profile-detailbox .chat-header h4{
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  text-decoration: underline;
  padding-right: 40px;
  }
  .chat-messages {
  /* flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f4ebe3;
  border-radius: 15px;
  color: #FFFFFF;
  margin-bottom: 10px; */
  height: 100%;
  margin-bottom: 10px;
  position: relative;
  }
  .chat-messages .message-meta{
  line-height: 16px;
  }
  .chat-messages .date-title{
  font-size: 12px;
  color: #5A5A5A;
  }
  .chat-message-body{
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f4ebe3;
  border-radius: 15px;
  color: #FFFFFF;
  margin-bottom: 10px;
  overflow-y: auto;
  height: 100%;
  position: relative;
  }
  .chat-message-body::-webkit-scrollbar, .chatBox-container .guest-profile-detailbox .guest-profile-container::-webkit-scrollbar {
  margin: 10px 0;
  width:8px;
  }
  .chat-message-body::-webkit-scrollbar-track, .chatBox-container .guest-profile-detailbox .guest-profile-container::-webkit-scrollbar-track {
  background: transparent;
  }
  .chat-message-body::-webkit-scrollbar-thumb , .chatBox-container .guest-profile-detailbox .guest-profile-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  }
  .chat-message {
  margin-bottom: 20px;
  padding: 8px;
  border-radius: 10px;
  position: relative;
  margin-top: 5px;
  min-width: 35px;
  }
  .message-meta{
  display: flex;
  justify-content: flex-end;
  }
  .message-meta.host{
  align-self: flex-end;
  }
  .message-meta.guest{
  align-self: flex-start;
  }
  .message-time{
  font-size: 12px;
  color: #5A5A5A;
  font-weight: 500;
  display: flex;
  }
  .message-time .message-title{
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
  color: #000;
  }
  .chat-message.guest {
  background-color: #e7e3d9;
  color: #25211e;
  font-weight: 500;
  align-self: flex-start;
  max-width: 60%;
  }
  .chat-message .message-text{
  font-size: 13px;
  line-height: normal;
  white-space: pre-wrap;
  word-break: break-word;
  }
  .chat-message.host {
  background-color: #25211e;
  color: white;
  align-self: flex-end;
  max-width: 60%;
  }
  /* Input area styling */
  .chat-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  background-color: #fef8ef;
  border-radius: 15px;
  position: sticky;
  bottom: 0;
  }
  .chat-input .emoji-custom-box{
  position: relative;
  }
  .chat-input .emoji-custom-box aside{
  left: auto !important;
  top: auto !important;
  bottom: 160px;
  right: auto;
  }
  .chat-input .send-ai-box{
  display: flex;
  align-items: center;
  }
  .chat-input .message-input-box{
  width: 100%;
  margin-right: 8px;
  margin-left: 8px;
  box-shadow: none;
  resize: none;
  background: rgba(0, 0, 0, 0.04);
  border: 2px solid rgba(0, 0, 0, 0.08);
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  height: 52px;
  padding: 10px;
  line-height: 16px;
  padding-top: 18px;
  }
  .chat-input .message-input-box:focus,.chat-input .message-input-box:focus-visible{
  outline: none;
  }
  .chat-input .message-input-box::placeholder{
  color: #99948f;
  font-weight: 500;
  }
  .chat-input .file-upload-btn , .chat-input .edit-message-btn , .chat-input .send-message-btn{
  background: #272525;
  border-radius: 50%;
  margin-left: 10px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin-left: 0;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  }
  .chat-input .file-upload-btn::after , .chat-input .edit-message-btn::after , .chat-input .send-message-btn::after , .chat-input .ai-on-btn::after{
  display: none;
  }
  .chat-input button img{
  max-width: unset;
  }
  .chat-input .ai-on-btn{
  background: #f9ee71;
  border-radius: 50%;
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin-left: 0;
  border: 2px solid #272525;
  padding: 0px;
  justify-content: center;
  align-items: center;
  }
  .chat-input .ai-on-btn span{
  display: block;
  line-height: normal;
  font-size: 13px;
  font-weight: 600;
  }
  .chat-input .file-upload-btn:hover , .chat-input .edit-message-btn:hover , .chat-input .ai-on-btn:hover , .chat-input .ai-on-btn:focus{
  border: none;
  color: #272525;
  }
  .chat-input .ai-on-btn:hover , .chat-input .ai-on-btn:focus{
  border: 2px solid #272525;
  }
  .chat-input .ai-on-btn:hover span{
  color: #272525;
  transition: none;
  }
  .chat-input .send-message-btn{
  margin: 0 10px;
  }
  .chat-input .edit-message-btn {
  margin-right: 10px;
  }
  .chat-input .file-upload-btn svg{
  fill: #fff;
  }
  /* Select guest message styling */
  .select-guest-message, .guest-details, .header-container .ant-page-header-heading-title {
  color: #EAEAEA;
  }
  /* Guest name styling */
  .guest-name {
  color: #FFD700;
  }
  /* Button styling */
  .ant-btn-primary {
  background-color: #FFA500; /* Unified button color */
  border-color: #FFA500;
  }
  .ant-btn-primary:hover {
  background-color: #FF8C00; /* Slightly darker on hover */
  border-color: #FF8C00;
  }
  .guest-search-filter{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  }
  /* .guest-search-filter button{
  margin: 0;
  padding: 0;
  margin-left: 10px;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  } */
  /* IconStyles.css */
  /* General icon styling */
  .icon {
  font-size: 24px;
  color: #FF8C00;
  }
  /* Larger icon style */
  .large-icon {
  font-size: 32px;
  }
  /* Red icon style */
  .red-icon {
  color: red;
  }
  /* Hover effect for icons */
  .icon:hover {
  color: #FF4500; /* Darker orange on hover */
  transform: scale(1.1); /* Slightly enlarges the icon on hover */
  }
  /* Specific styles for different icons */
  .send-icon {
  color: #4CAF50; /* Green color for send icon */
  }
  .picture-icon {
  color: #2196F3; /* Blue color for picture icon */
  }
  .edit-icon {
  color: #FFC107; /* Amber color for edit icon */
  }
  /* Additional styling for buttons with icons */
  .icon-button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  }
  /* Added by Gautam 13-09-2024 */
  /* Adjusting padding, margins, and shadows for a more polished UI */
  /* Profile Section */
  .chatBox-container .guest-profile-detailbox {
  min-width: 375px;
  max-width: 375px !important;
  border-radius: 0 15px 15px 0;
  margin-bottom: 0 !important;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-head{
  display: none;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body{
  padding-left: 0 !important;
  height: 100%;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container{
  background-color: black;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  border-radius: 10px;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .guest-profile-title{
  text-decoration: underline;
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 700;
  }
  .guest-info{
  position: relative;
  }
  .chatBox-container .guest-profile-detailbox .profile-section {
  display: flex;
  padding: 5px 10px;
  background-color: #f3ebe3;
  color: black;
  border-radius: 10px;
  margin-bottom: 20px;
  gap: 10px;
  font-size: 12px;
  }
  .guest-info h2 {
  font-size: 1rem;
  text-decoration: underline;
  }
  .guest-info p {
  margin: 5px 0;
  }
  .chatBox-container .guest-profile-detailbox .reservation-button {
  color: rgba(37, 33, 30, 1);
  text-decoration: none;
  background: rgba(123, 232, 255, 1);
  border: 1.5px solid rgba(37, 33, 30, 1);
  border-radius: 50px;
  padding: 6px 15px;
  display: block;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  text-transform: uppercase;
  }
  /* Status Cards */
  .status-card {
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  .early-checkin h3, .upgrades h3 {
  font-size: 1.2rem;
  font-weight: bold;
  }
  .early-checkin p, .upgrades p {
  margin: 5px 0;
  }
  /* Footer Section */
  .footer-section {
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Footer shadow */
  }
  .note-section {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  }
  .chatBox-container .guest-profile-detailbox .checkin-section .checkin-details{
  padding: 10px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  }
  .chatBox-container .guest-profile-detailbox .checkin-section .checkin-details p{
  color: #dbdbdb;
  font-weight: 300;
  }
  .chatBox-container .guest-profile-detailbox .checkin-section .checkin-details p span{
  display: block;
  font-weight: 500;
  }
  .upgrades{
  background-color: rgba(255, 141, 232, 1);
  gap: 80px;
  }
  .chatBox-container .guest-profile-detailbox .profile-section img{
  min-width: 100px;
  max-width: 100px;
  border-radius: 10px;
  min-height: 185px;
  object-fit: cover;
  flex: 1;
  }
  .chatBox-container .guest-profile-detailbox .profile-section img.broken-guest-img{
  object-fit: contain;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info{
  flex: 1;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info p{
  color: #8E90AA;
  font-weight: 500;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info .link p , .chatBox-container .guest-profile-detailbox .profile-section .guest-info .link a{
  color: #000;
  font-weight: 600;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info  .toggle-container .ant-switch{
  min-width: 40px;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info  .toggle-container .ant-switch .ant-switch-handle{
  width: 12px;
  height: 12px;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info  .toggle-container .switch-toggle{
  height: 18px !important;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info  .toggle-container span{
  font-size: 12px;
  font-weight: 600;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info  .toggle-container .ant-switch-handle{
  left: 4px;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info  .toggle-container .ant-switch-checked .ant-switch-handle{
  left: calc(100% - 14px - 2px);
  }
  .guest-profile-container p, .guest-profile-container h2, .guest-profile-container h3{
  margin: 0;
  padding: 0;
  }
  .chatBox-container .guest-profile-detailbox .checkin-section{
  position: relative;
  z-index: 1;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  }
  .chatBox-container .guest-profile-detailbox .checkin-section .check-in-image{
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
  }
  .chatBox-container .guest-profile-detailbox .status-card{
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 20px;
  }
  .chatBox-container .guest-profile-detailbox .status-card.early-checkin{
  background: rgba(255, 237, 73, 1);
  }
  .chatBox-container .guest-profile-detailbox .status-card h3{
  color: #000;
  font-size: 15px;
  font-weight: 600;
  }
  .chatBox-container .guest-profile-detailbox .status-card div{
  width: 35%;
  }
  .chatBox-container .guest-profile-detailbox .status-card ul{
  flex: 1;
  }
  .chatBox-container .guest-profile-detailbox .status-card li{
  color: #696982;
  font-weight: 500;
  font-size: 14px;
  }
  .chatBox-container .guest-profile-detailbox .status-card li:nth-child(2){
  color: #000;
  text-decoration: underline;
  font-weight: 600;
  }
  /* Flex container for the toggle and label */
  .toggle-container {
  display: flex;
  align-items: center; /* Aligns the toggle switch and text vertically */
  margin-top: 10px;
  }
  .toggle-container span {
  margin-left: 10px; /* Space between toggle and label */
  font-size: 14px;
  line-height: 1.5;
  }
  .guest-profile-container .link{
  margin-top: 10px;
  }
  .unread-indicator {
  width: 10px;
  height: 10px;
  background-color: #f9c268;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  }
  /* .scrollToBottomIcon{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: grey;
  position: fixed;
  bottom: 0;
  } */
  .scroll-to-bottom {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  background-color: #f9c268;
  color: #fff;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  cursor: pointer;
  border: none;
  opacity: .8;
  }
  .scroll-to-bottom:hover{
  background-color: #000;
  }
  .chat-input {
  position: relative;
  }
  .chat-input aside.emoji-picker-react{
  position: fixed !important;
  z-index: 10 !important;
  }
  .show-chat{
  visibility: visible;
  }
  .hidden-chat{
  visibility: hidden;
  }
  .checkin-alert{
  background-color: #FFED49;
  }
  .checkin-date{
  background-color: #7BE8FF;
  }
  .checkout-date{
  background-color: #7BE8FF;
  }
  .checkout-alert{
  background-color: #FFED49;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body .checkout-date p, .chatBox-container .guest-profile-detailbox .ant-card-body .checkin-alert p, .chatBox-container .guest-profile-detailbox .ant-card-body .checkin-date p, .chatBox-container .guest-profile-detailbox .ant-card-body .checkout-alert p{
  color: #000;
  font-weight: 500;
  font-size: 11px;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body .checkin-alert{
  position: relative;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body .checkout-alert{
  position: relative;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkout::after, .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkin::after{
  content: '';
  position: absolute;
  width: 135%;
  height: 2px;
  background: #000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(227deg);
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body .checkout-date p span,.chatBox-container .guest-profile-detailbox .ant-card-body .checkin-date p span{
  font-weight: 700;
  display: block;
  }
  .gust-detail-card-section{
  gap: 5px;
  }
  .checkout-date, .checkin-alert, .checkin-date, .checkout-alert{
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  min-height: 85px;
  padding: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 78px;
  }
  .no-guest-found{
  text-align: center;
  }
  .no-guest-found img{
  width: 50%;
  }
  .no-guest-data-availble{
  background: #fff8ef;
  border-radius: 24px;
  min-height: calc(100vh - 50px);
  padding: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  }
  .no-guest-data-availble p{
  margin-top: 15px;
  }
  .no-guest-found p{
  font-weight: 600;
  margin-top: 10px;
  color: black;
  }
  .new-message{
  position: absolute;
  top: 8px;
  left: 40px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #ffc062;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info .link a{
  text-decoration: underline;
  }
  .qrcode-icon{
    width: 26px;
    height: 26px !important;
    border-radius: 50%;
    background: #ffc602;
    padding: 5px;
    margin-left: 6px;
  }
  .qrcode-link{
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  .qrcode-div{
    display: flex;
    align-items: flex-end;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .react-loading-skeleton{
    --base-color: #e0e0e0;
    --highlight-color: #f3ebe3;
    border-radius: 10px;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .image-skelton .react-loading-skeleton{
    border-radius: 10px;
    height: 180px;
    min-width: 100px;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .guest-info .react-loading-skeleton{
    height: 16.6px;
    width: 100%;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .gust-detail-card-section .checkin-skeleton{
    height: 85px;
    text-align: center;
    width: 78px;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .gust-detail-card-section .checkin-skeleton .react-loading-skeleton{
    height: 100%;
  }
  .pms-card {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;
    border: 2px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 15px;
  }
  .pms-container .ant-card-body{
    min-height: calc(100vh - 75px);
  }
  .pms-img {
    display: block;
    text-align: center;
    margin: 0 auto 30px;
    width: 250px;
  } 
  .placeholder-pms-img{
    width: auto;
    margin-bottom: 18px;
  }
  .pms-button {
    background-color: #ffc062;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    color: #25211e;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;
    width: 100%;
    font-weight: 700;
    height: 44px;
  }
  .disable-style-btn{
    background-color: #ccc;
    pointer-events: none;
  }  
  .coverage_table_card_action .table-responsive .ant-table-content{
    min-height: 30vh;
  }
  .common_fiter_table.brder-radius-0{
    border-radius: 0;
  }
  .sc-gEvEer.custom_layout_section .ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item{
    padding: 0 !important;
  }
  .sc-gEvEer.custom_layout_section .ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item .menuItem-iocn{
    padding-left: 15px;
  }
  .sc-gEvEer.custom_layout_section .ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.pms-inter-mob-li{
    display: none;
  }
  .project-modal.selecet-customloader .sk-circle{
    width: 26px;
    height: 26px;
    top: 11px;
  }

  .project-modal.selecet-customloader .ant-select .ant-select-arrow{
    display: none;
  }
  .inline-loader-pms{
    color: #fff;
    padding: 0 12px;   
  }
  .waiverbox.waiverboxPopup .guestPayout p.host_add_price_para{
    margin-left: 5px;
  }
  .waiver_amount_line_through {
    text-decoration: line-through;
    color: red;
  }
  .waiver_amount_new_value {
    color: green;
  }
  .waiverbox.waiverboxPopup .guestPayout .text-color {
    color: #000;
  }
  .refund-status{
    display: flex;
    align-items: center;
  }
  .refund-btn{
    margin-left: 10px;
  }
  .refundmodal{
    margin-bottom: -20px;
  }
  .upsell-item-quantity{
    width: 200px;
  }
  .custom_layout_section .ant-form-item-control-input .ant-input-number.max-quantity-input input , .custom_layout_section .ant-form-item-control-input .ant-input-number.max-quantity-input .ant-input-number-handler-wrap{
    background-color: #f3eee6 !important;
  }
  .custom_layout_section .ant-form-item-control-input .ant-input-number.max-quantity-input{
    border: 2px solid #e1dbd4 !important;
  }
  .custom_layout_section .ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item .ant-menu-title-content{
    padding-left: 0 !important;
  }
  .custom_layout_section .ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item .ant-menu-title-content a{ 
    padding-left:7px;
  }
  .no-resize-textarea {
    resize: none;
  }
  .ant-modal.delete_listing_modal{
    width: 615px !important;
  }
  @media (max-width:1770px){
  .coverage_row_sec ul li label span{
  min-height: 45px;
  display: flex;
  }
  .coverage_row_sec ul li label span .requiredFiled{
  margin-right: 2px;
  }
  .coverage_row_sec ul:first-child li.partner_delete_icon_btn button{
  top: 58px;
  }
  }
  @media (max-width:1680px){
  .ant-layout-sider-children{
  height: calc(100% - 90px);
  }
  }
  @media (max-width:1580px){
  .upsell-item{
  width: 125px;
  }
  .upsell-item-price{
  width: 100px;
  }
  .upsell-item-desc, .upsell-item-postpurch{
  width: 230px;
  }
  }
  @media (max-width: 1536px){
  .check_in_url_input{
  width: 100%;
  }
  .contact-table.host_list_table .ant-table-thead > tr > th{
  padding: 20px 6px !important;
  }
  .contact-table.host_list_table .ant-table-container table > thead > tr:first-child th:last-child , .ant-table-container table > tbody > tr td:last-child{
  padding-right: 15px !important;
  }
  .contact-table.host_list_table .ant-table-container table > tbody > tr td{
  padding: 20px 6px 15px !important;
  }
  .contact-table.host_list_table .ant-table-container table > tbody > tr td:first-child{
  padding-left: 45px !important;
  }
  .coverage_row_sec ul li:nth-child(7){
  min-width: 175px;
  }
  .common_label_wrap_text .ant-form-item-label label{
  min-height: 90px;
  }
  }
  @media (max-width:1440px){
  .upsell-item-desc, .upsell-item-postpurch{
  width: 215px;
  }
  }
  @media (max-width: 1399px){
  .common_label_wrap_text .ant-form-item-label label{
  /* min-height: 68px; */
  }
  .coverage_row_sec ul li:nth-child(7){
  min-width: 140px;
  }
  .coverage_row_sec ul li:nth-child(8){
  min-width: 125px;
  }
  .coverage_row_sec ul li:nth-child(6){
  min-width: 115px;
  }
  .coverage_row_sec ul li:nth-child(4){
  min-width: 100px;
  }
  .custom-sell-item .mr-10{
  margin-right: 5px;
  }
  .upsell-item-price{
  width: 90px;
  }
  .upsell-item-desc, .upsell-item-postpurch{
  width: 190px;
  }
  .guest-list-container {
  min-width: 300px;
  max-width: 300px;
  }
  }
  @media (max-width: 1300px){
  .upsell-item-desc, .upsell-item-postpurch{
  width: 160px;
  }
  .upsell-auto-approve {
  width: 80px;
  }
  .upsell-item-desc, .upsell-item-postpurch{
  width: 160px;
  }
  .ant-layout-content .inbox-container{
  min-height: unset;
  }
  }
  @media (max-height:857px){
    .pms-container .ant-card-body {
      min-height: calc(100vh - 175px);
    }
  }
  @media (max-height: 685px) {
    aside.ant-layout-sider .profile_menu_box ul{
      overflow-y: auto;
      height: 450px;
    }
    aside.ant-layout-sider .profile_menu_box ul::-webkit-scrollbar {
      margin: 10px 0;
      width:5px;
    }
    aside.ant-layout-sider .profile_menu_box ul::-webkit-scrollbar-track{
      background: transparent;
    }
    aside.ant-layout-sider .profile_menu_box ul::-webkit-scrollbar-thumb{
      background: #ffc062;
      border-radius: 6px;
    }
    aside.ant-layout-sider .profile_menu_box{
      min-height: calc(100vh - 135px);
    }
    aside.ant-layout-sider .profile_menu_box ul{
      height: 430px;
    }
    aside.ant-layout-sider .profile_menu_box {
      min-height: calc(100vh - 165px);
    }
    .pms-container .ant-card-body {
      min-height: calc(100vh - 195px);
    }
    .ant-layout .sc-cwHptR.inbox-container.pms-container{
      min-height: unset;
    }
  }

  @media (max-height: 700px) {
    .ant-layout .sc-cwHptR.inbox-container{
      min-height: 645px;
    }
  }
  
  @media (max-height: 600px) {
    aside.ant-layout-sider .profile_menu_box ul{
      height: 380px;
    }
  }
  
  @media (max-height: 565px) {
    aside.ant-layout-sider .profile_menu_box{
      min-height: calc(100vh - 155px);
    }
    aside.ant-layout-sider .profile_menu_box ul{
      height: 325px;
    }
  }
  
  @media (max-height: 500px) {
    aside.ant-layout-sider .profile_menu_box ul{
      height: 300px;
    }
  }
  @media (min-width: 1201px){
  .tab_view_header{
  display: none;
  }
  }
  @media (max-width: 1200px){
  .mobile-Header-Drodown{
  z-index: 99;
  }
  .upsell-item-desc, .upsell-item-postpurch{
  width: 200px;
  }
  .bfPDiT .ant-layout-sider.ant-layout-sider-collapsed + .atbd-main-layout{
  margin-left: 0 !important;
  }
  .tab_view_header{
  padding: 20px 30px 0;
  }
  .tab_view_header .top_header_section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  .tab_view_header .top_header_section .logo_box a{
  margin-bottom: 0 !important;
  }
  .tab_view_header .top_header_section .top_header_content_box{
  display: flex;
  z-index: 999;
  }
  .ant-layout-sider{
  display: none;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox{
  display: flex;
  align-items: center;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu{
  margin-right: 25px;
  border-radius: 21px;
  background-color: rgb(255, 192, 98);
  line-height: 40px;
  padding: 0 15px;
  color: #000;
  font-weight: 700;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu .change_dropdown_text svg{
  transform: rotate(180deg);
  transition: all 0.3s ease;
  margin-top: 4px;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu .ant-dropdown-trigger.ant-dropdown-open .change_dropdown_text svg{
  transform: rotate(0deg);
  transition: all 0.3s ease;
  margin-top: 0px;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu .change_dropdown_text{
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu .change_dropdown_text svg{
  margin-left: 5px;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu .ant-dropdown-trigger{
  color: #000;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .custom_auth_box{
  position: relative;
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  padding-left: 17px;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .custom_auth_box .autoprofile{
  border-top: none;
  padding-top: 0;
  }
  /* .tab_view_header .top_header_section .top_header_content_box .rBox .custom_auth_box .settings{
  position: absolute;
  margin: 0;
  left: 35px;
  display: none;
  top: 50px;
  background: #fef8ef;
  padding: 6px 18px;
  color: #000 !important;
  border-radius: 12px;
  box-shadow: 0 5px 30px #9299b820;
  z-index: 1;
  height: 50px;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .custom_auth_box .settings::after{
  content: '';
  position: absolute;
  border-top: 10px solid #fef8ef;
  border-left: 10px #fef8ef;
  border-right: 10px solid transparent;
  transform: rotate(45deg);
  right: 50px;
  top: -5px;
  } */
  /* .tab_view_header .top_header_section .top_header_content_box .rBox .custom_auth_box .settings img{
  filter: invert(1);
  } */
  /* .tab_view_header .top_header_section .top_header_content_box .rBox .custom_auth_box:hover .settings{
  display: flex;
  align-items: center;
  justify-content: center;
  } */
  .ant_dropdown_custom {
  background: transparent;
  }
  .mobile_view_dropdown{
  overflow: hidden;
  background: #25211e;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  border: 1px solid #25211E;
  }
  .mobile_view_dropdown .ant-dropdown-menu{
  background: #25211e;
  border-radius: 16px;
  padding: 35px 0 12px;
  min-width: auto;
  }
  .mobile_view_dropdown .ant-dropdown-menu li{
  padding: 8px 14px;
  }
  .mobile_view_dropdown .ant-dropdown-menu li a{
  padding: 0;
  color: #25211E;
  font-weight: 700;
  font-size: 14px;
  }
  .mobile_view_dropdown .ant-dropdown-menu li a.active{
  color: #000;
  font-weight: 700;
  }
  .mobile_view_dropdown .ant-dropdown-menu li.ant-dropdown-menu-item-selected{
  background-color: #fdf8ef;
  }
  .mobile_view_dropdown .ant-dropdown-menu li.ant-dropdown-menu-item-selected a{
  color: #000;
  font-weight: 700;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu{
  width: 178px;
  border: 1px solid #000;
  z-index: 99;
  }
  .ant_dropdown_custom{
  overflow: unset;
  max-width: 178px;
  min-width: 178px !important;
  right: 255px;
  left: inherit !important;
  top: 50px !important;
  z-index: 99;
  }
  .mobile_view_dropdown, .mobile_view_dropdown .ant-dropdown-menu{
  background:#fef8ef;
  }
  .mobile_view_dropdown .ant-dropdown-menu li .ant-dropdown-menu-item-icon {
  filter: invert(1);
  max-width: 16px;
  }
  .ant-dropdown-menu-item-icon
  .user_profile_box .user_profile_option{
  width: 172px;
  top: auto;
  bottom: -108px;
  border-top: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 26px 0 0;
  }
  .settings{
  font-size: 12px;
  }
  .user_profile_box{
  z-index: 99;
  }
  .user_profile_option.new_menu_item_add{
  width: 172px;
  bottom: -146px;
  }
  /* .tab_view_header{
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  background: #25211e;
  } */
  .top_header_sticky{
  position: sticky;
  top: 0;
  z-index: 11;
  background: #25211e;
  }
  .table-responsive .ant-table-content{
  min-height: calc(100vh - 308px);
  max-height: calc(100vh - 308px);
  }
  .user_profile_box .user_profile_option{
  top: auto;
  bottom: -123px;
  left: -1px;
  border-top: none;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 26px 0 10px;
  width: 181px;
  }
  .pms-user_profile_box.user_profile_box .user_profile_option{
    top: auto;
  }
  .payout-setting-profile-box.user_profile_box .user_profile_option, .payout-menu.user_profile_box .user_profile_option{
    top: auto;
    bottom: -155px;
  }
  .guest-list-container, .chat-area-container, .guest-details-container {
  height: calc(100vh - 145px);
  }
  /* .mainBlock{
  padding-top: 5px !important;
  }
  .ant-card{
  margin-top: 85px;
  } */
  .new-message{
  left: 76px;
  }
  .user_profile_box .user_profile_option .pms-intergation-desktop{
    display: none;
  }
  }
  @media(max-width: 1199px){
  .tab_view_header{
  padding: 20px 15px;
  }
  .ant_dropdown_custom{
  right: 240px;
  }
  .ninjadash-shade.show{
  display: none !important;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu .change_dropdown_text{
  line-height: normal;
  padding: 10px 0;
  }
  .ant-layout-content .inbox-container{
  min-height: 685px;
  }
  }
  @media (max-width: 1024px){
  .coverage_row_sec ul li:nth-child(6){
  min-width: 125px;
  }
  .coverage_row_sec ul li:nth-child(7){
  min-width: 130px;
  }
  .upsell-item-desc, .upsell-item-postpurch{
  width: 160px;
  }
  }
  @media (min-width: 992px){
  .chat-backarrow-mobile , .guest-profile-detailbox .chat-header{
  display: none;
  }
  }
  @media (max-width: 991px){
  .common_label_wrap_text .ant-form-item-label label{
  min-height: 45px;
  }
  .coverage_row_sec ul:first-child li.partner_delete_icon_btn button{
  top: 36px;
  }
  .upsell-auto-approve{
  width: 85px;
  }
  .custom-sell-item .project-modal{
  padding-left: 3px !important;
  padding-right: 3px !important;
  }
  .upsell-service-fee{
  width: 150px;
  }
  .custom-sell-item .ant-input-affix-wrapper.wifi{
  padding-left: 3px !important;
  padding-right: 3px !important;
  }
  .chatBox-container{
  display: block;
  }
  .guest-list-container, .guest-details-container{
  height: auto;
  }
  .guest-list-container{
  width: 100%;
  max-width: unset;
  min-width: unset;
  }
  .guest-list-cards{
  border-radius: 15px;
  }
  .guest-list-cards .ant-card-body{
  padding-right: 25px !important;
  }
  .chat-area-container .chat-area-cards{
  border-radius: 15px;
  }
  .chatBox-container .guest-profile-detailbox{
  min-width: unset;
  max-width: unset !important;
  width: 100%;
  border-radius: 15px;
  }
  .chat-area-container .nochat-box{
  display: none;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body{
  padding-left: 25px !important;
  }
  .chat-backarrow-mobile{
  margin-right: 15px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  background: #ffc062;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container{
  background-color: transparent;
  }
  .chatBox-container .guest-profile-detailbox .profile-section{
  background-color: transparent;
  }
  .guest-profile-detailbox .chat-header{
  justify-content: center;
  }
  .guest-profile-detailbox .guest-name{
  flex-grow: 1;
  text-align: center;
  }
  .chatBox-container .guest-profile-detailbox .profile-section , .chatBox-container .guest-profile-detailbox .guest-profile-container{
  padding: 0;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container{
  height: auto;
  }
  .ant-layout-content .inbox-container{
  min-height: unset;
  padding-bottom: 25px;
  }
  .chat-area-container{
  height: calc(100vh - 265px);
  margin-bottom: 25px;
  }
  .chat-area-container.chat-area-border-radius .chat-area-cards{
  border-radius: 15px;
  }
  .chat-area{
  height: calc(100% - 185px);
  }
  .chat-area .chat-header h4{
  padding-right: 10px;
  }
  .checkout-date, .checkin-alert, .checkin-date, .checkout-alert{
  width: 24.33%;
  }
  .chatBox-container .guest-profile-detailbox .profile-section{
  gap: 24px;
  }
  .chatBox-container .guest-profile-detailbox .profile-section img{
  min-width: 300px;
  max-width: 300px;
  max-height: 185px;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkout::after, .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkin::after{
  width: 100%;
  transform: translate(-50%, -50%) rotate(199deg);
  }
  .chat-area-container{
  height: calc(100vh - 195px);
  }
  .guest-list{
  height: calc(100vh - 345px);
  }
  .guest-list-cards .left-card-box .show-more-box{
  padding: 20px 0 0px;
  }
  .guest-profile-detailbox{
  height: calc(100vh - 170px);
  }
  .chat-area-container::after {
    display: none;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .image-skelton .react-loading-skeleton{
    min-width: 300px;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .gust-detail-card-section .checkin-skeleton{
    width: 24.33%;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .image-skelton .react-loading-skeleton{
    height: 182px;
  }
  .gust-detail-card-section.skelton-loading-box{
    min-height: 90px;
  }
  }
  @media (max-width:820px){
  .coverage_row_sec ul{
  flex-wrap: wrap;
  }
  .coverage_row_sec ul li{
  flex: unset;
  width: 33.33%;
  }
  .coverage_row_sec ul li:nth-child(3),.coverage_row_sec ul li:nth-child(7){
  max-width: none;
  min-width: unset;
  }
  .coverage_row_sec ul li label span , .partner_common_label .ant-form-item-label label{
  min-height: auto;
  }
  .common_label_wrap_text .ant-form-item-label label{
  min-height: 66px;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkout::after, .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkin::after{
  width: 108%;
  transform: translate(-50%, -50%) rotate(205deg);
  }
  }
  @media(min-width:768px){
  .mobile_view_dropdown .mobileview_userPro_box , .mobile_view_serach{
  display: none;
  }
  }
  @media (max-width: 767px){
  .ant-page-header-heading-extra{
  margin-top: 0 !important;
  }
  .coverage-table{
  margin-left: 0px;
  }
  .coverage-detail-table{
  margin-left: 0px;
  }
  .partner-detail-table{
  margin-left: -10px;
  }
  .user_profile_box .user_profile_option a span{
  line-height: normal;
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before , .ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after{
  box-shadow: none;
  }
  .listing_pagheader{
  flex-direction: column-reverse;
  padding-top: 0;
  }
  .listing_pagheader .ant-page-header-heading{
  align-items: end;
  }
  .ant-table-container table > tbody > tr::after{
  left: 4px;
  width: 99.5%;
  }
  .ant-table-container table > tbody > tr:hover::after{
  left: 4px;
  width: 99.5%;
  }
  .common_fiter_table.listin_card{
  padding: 0;
  }
  .ant-table-pagination.ant-pagination{
  padding-right: 0;
  justify-content: center;
  }
  .ant-table-pagination li.ant-pagination-total-text{
  text-align: center;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  width: 100%;
  }
  .ninjadash-shade.show{
  display: none !important;
  }
  .ant-table-container table > tbody > tr td:first-child{
  padding-left: 20px !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child{
  padding-left: 15px !important;
  }
  .mobile_view_dropdown .mobileview_userPro_box{
  display: block;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .custom_auth_box{
  display: none;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu{
  margin-right: 0;
  }
  .pageheaderButton.mobile_view_hide{
  display: none;
  }
  .horizontal_cust_tablist{
  width: 100% !important;
  justify-content: center;
  }
  .global_search_filter.upcoming_filter_tab_btn{
  border-top-left-radius: 24px;
  }
  .pageheader .ant-menu .ant-menu-item{
  padding: 0 18px;
  }
  .mobile_view_dropdown{
  overflow: hidden;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  border: 1px solid #25211E;
  }
  .tab_view_header .top_header_section .top_header_content_box .rBox .cus_drop_down_menu{
  width: 178px;
  border: 1px solid #000;
  z-index: 99;
  }
  .mobile_view_dropdown .ant-dropdown-menu li .ant-dropdown-menu-item-icon , .mobileview_userPro_box .settings img{
  filter: invert(1);
  }
  .mobileview_userPro_box .welcome{
  color: #25211E;
  display: none;
  }
  .mobileview_userPro_box .ninjadash-nav-actions__author--name{
  color: #25211E;
  font-size: 14px;
  }
  .mobileview_userPro_box .settings{
  color: #25211E !important;
  margin: 0;
  padding: 10px 0;
  font-weight: 700 !important;
  }
  .mobile_view_dropdown .ant-dropdown-menu{
  padding: 25px 0 0;
  }
  .mobile_view_dropdown .ant-dropdown-menu li{
  padding: 5px 14px;
  }
  .autoprofile{
  border-top: 1px solid #25211e1a;
  padding-top: 15px;
  border-radius: 0 !important;
  margin: 10px 14px;
  }
  .autoprofile .ninjadash-nav-action-link{
  padding: 0 !important;
  }
  .autoprofile .ant-avatar-image{
  display: inline-block;
  }
  .autoprofile .ant-avatar-image img{
  width: 100%;
  height: 100%;
  }
  .ant_dropdown_custom{
  overflow: unset;
  max-width: 178px;
  min-width: 178px !important;
  right: 15px;
  left: inherit !important;
  top: 50px !important;
  }
  .tab_view_header .top_header_section .logo_box a img{
  width: 100px;
  }
  .user_profile_box{
  flex-wrap: wrap;
  }
  .settings{
  font-size: 14px;
  }
  .user_profile_box .user_profile_option{
  all: unset;
  }
  .user_profile_box .user_profile_option a{
  padding: 10px 12px 0px 0;
  }
  .ant-modal.update_profile_modal .profile_update_section .photo-upload .ant-upload-select{
  right: 30px;
  }
  .image_upload_box .upload-section .photo-upload .ant-upload-select{
  right: -8px;
  }
  .table-responsive .ant-table-content{
  min-height: calc(100vh - 385px);
  max-height: calc(100vh - 385px);
  }
  .check_in_url_input{
  margin: 10px auto 0px;
  }
  .delete_icon_btn .ant-btn-error{
  top: 46px;
  }
  .common_label_wrap_text .ant-form-item-label label{
  min-height: auto;
  }
  .coverage_row_sec ul li{
  width: 100%;
  }
  .coverage_row_sec ul li.partner_delete_icon_btn{
  padding-left: 0;
  }
  .coverage_row_sec ul li.partner_delete_icon_btn button , .coverage_row_sec ul:first-child li.partner_delete_icon_btn button{
  top: 0;
  }
  .custom-sell-item{
  flex-wrap: wrap;
  }
  .upsell-item-price,.upsell-item,.upsell-item-desc, .upsell-item-postpurch,.upsell-auto-approve,.upsell-service-fee {
  width: 100%;
  }
  .custom-sell-item .common_label_wrap_text .ant-form-item-label label{
  min-height: auto;
  }
  .custom-sell-item .upsell-delete-btn .ant-btn-error{
  position: static;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body{
  padding-left: 15px !important;
  }
  .gust-detail-card-section{
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 0px;
  }
  .checkout-date, .checkin-alert, .checkin-date, .checkout-alert{
  width: 48%;
  margin-bottom: 12px;
  }
  .chatBox-container .guest-profile-detailbox .profile-section img{
  min-width: 100px;
  max-width: 100px;
  }
  .chatBox-container .guest-profile-detailbox .profile-section{
  gap: 10px;
  }
  .guest-list{
  height: calc(100vh - 285px);
  }
  .chat-area-container {
  height: calc(100vh - 170px);
  }
  .new-message{
  left: 78px;
  top: 5px;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .image-skelton .react-loading-skeleton{
    max-width: 100px;
    min-width: 100px;
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .gust-detail-card-section .checkin-skeleton{
    width: 48%;
    margin-bottom: 12px;
  }
  .sc-gEvEer.custom_layout_section .ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.pms-inter-mob-li{
    display: block;
  }
  .ant-modal.delete_listing_modal{
    width: auto !important;
 }
}
  @media (max-width: 576px){
  .chat-area .chat-header h4, .guest-profile-detailbox .chat-header h4{
  font-size: 22px;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkout::after, .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkin::after{
  width: 102%;
  transform: translate(-50%, -50%) rotate(204deg);
  }
  .chatBox-container .guest-profile-detailbox .guest-profile-container .react-loading-skeleton{
    height: 185px;
  }
  }
  @media(max-width: 390px){
  .ant-modal.update_profile_modal .profile_update_section .photo-upload .ant-upload-select{
  right: 20px;
  }
  .chat-area .chat-header h4 , .guest-profile-detailbox .chat-header h4{
  font-size: 18px;
  }
  .checkout-date, .checkin-alert, .checkin-date, .checkout-alert{
  width: 47.66%;
  }
  .chatBox-container .guest-profile-detailbox .profile-section .guest-info .link a {
  word-break: break-word;
  }
  .chatBox-container .guest-profile-detailbox .reservation-button{
  padding: 6px 8px;
  }
  .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkout::after,   .chatBox-container .guest-profile-detailbox .ant-card-body .not-available-checkin::after  {
  width: 106%;
  transform: translate(-50%, -50%) rotate(208deg);
  }
  .chat-input .edit-message-btn{
  margin-right: 5px;
  }
  .chat-input .message-input-box {
  margin: 0;
  }
  .chat-input .send-message-btn{
  margin: 0 5px;
  }
  .chat-input button img{
  max-width: 14px;
  }
  .chat-input .file-upload-btn, .chat-input .edit-message-btn, .chat-input .send-message-btn, .chat-input .ai-on-btn{
  min-width: 32px;
  width: 32px;
  height: 32px;
  }
  .chat-input .ai-on-btn span{
  font-size: 12px;
  }
  }
  @media(max-width: 375px){
    .ant-modal.update_profile_modal .profile_update_section .photo-upload .ant-upload-select{
      right: 20px;
    }
    .chatBox-container .guest-profile-detailbox .guest-profile-container .gust-detail-card-section .checkin-skeleton{
      width: 47.65%;
    }
    .chatBox-container .guest-profile-detailbox .profile-section img{
      max-height: 244px;
    }
    .chatBox-container .guest-profile-detailbox .profile-section .guest-info p{
      word-break: break-all;
    }
  } 
